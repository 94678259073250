// extracted by mini-css-extract-plugin
export var aiChat__preview = "bx_lb";
export var aiChat__previewButton = "bx_ll";
export var aiChat__previewButtonWrapper = "bx_lk";
export var aiChat__previewContainer = "bx_lc";
export var aiChat__previewDescr = "bx_lj";
export var aiChat__previewFixedBottom = "bx_lm";
export var aiChat__previewLogo = "bx_lg";
export var aiChat__previewLogoWrapper = "bx_ld";
export var aiChat__previewTitle = "bx_lh";
export var pulseLightRed = "bx_lf";