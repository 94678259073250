// extracted by mini-css-extract-plugin
export var mvpAppDevBenefitsSection = "D_ff";
export var mvpAppDevCaseSection = "D_fn";
export var mvpAppDevChoiceSection = "D_fl";
export var mvpAppDevClientQuotes = "D_fp";
export var mvpAppDevCostSection = "D_fj";
export var mvpAppDevExpertiseSection = "D_fm";
export var mvpAppDevIndustriesSection = "D_fc";
export var mvpAppDevPracticesSection = "D_fk";
export var mvpAppDevPrimeSection = "D_d9";
export var mvpAppDevProcessSection = "D_fg";
export var mvpAppDevServicesSection = "D_fb";
export var mvpAppDevTechSection = "D_fh";
export var mvpAppDevTypesSection = "D_fd";