// extracted by mini-css-extract-plugin
export var multiVendorBenefitsSection = "F_fz";
export var multiVendorBusinessesSection = "F_fs";
export var multiVendorClinetsSection = "F_fF";
export var multiVendorDevExpertiseSection = "F_fB";
export var multiVendorDiscoverWorksSection = "F_fy";
export var multiVendorFeaturesSection = "F_fr";
export var multiVendorIdeaToLifeSection = "F_fx";
export var multiVendorIndustriesSection = "F_ft";
export var multiVendorOurAchievementsSection = "F_fD";
export var multiVendorProcessSection = "F_fv";
export var multiVendorServicesSection = "F_fq";
export var multiVendorSuccessStoriesSection = "F_fC";
export var multiVendorTechStackSection = "F_fw";