// extracted by mini-css-extract-plugin
export var servicesDevExpertiseCompany = "db_yb";
export var servicesDevExpertiseCompany__card = "db_yc";
export var servicesDevExpertiseCompany__emoji = "db_yf";
export var servicesDevExpertiseCompany__maxWidth140 = "db_yh";
export var servicesDevExpertiseCompany__maxWidth180 = "db_yj";
export var servicesDevExpertiseCompany__maxWidth190 = "db_yk";
export var servicesDevExpertiseCompany__maxWidth270 = "db_yl";
export var servicesDevExpertiseCompany__maxWidth310 = "db_ym";
export var servicesDevExpertiseCompany__text = "db_yg";
export var servicesDevExpertiseCompany__title = "db_yd";