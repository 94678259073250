// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bW_kv";
export var caseStudyBackground__lineColor = "bW_kl";
export var caseStudyHead__imageWrapper = "bW_mH";
export var caseStudyProjectsSection = "bW_kw";
export var caseStudyQuote__bgRing = "bW_kr";
export var caseStudyQuote__bottomVideo = "bW_kL";
export var caseStudyQuote__light = "bW_l9";
export var caseStudyQuote__lightLast = "bW_mJ";
export var caseStudyVideo__ring = "bW_kP";
export var caseStudy__bgDark = "bW_kh";
export var caseStudy__bgLight = "bW_kg";
export var caseStudy__bgLightReverse = "bW_kJ";