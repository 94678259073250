// extracted by mini-css-extract-plugin
export var discoverWorksItem = "cN_rs";
export var discoverWorksItem__arrowLink = "cN_rG";
export var discoverWorksItem__bg = "cN_rw";
export var discoverWorksItem__clientImg = "cN_rK";
export var discoverWorksItem__clientName = "cN_rL";
export var discoverWorksItem__clientPosition = "cN_rM";
export var discoverWorksItem__gradientAfricar = "cN_r0";
export var discoverWorksItem__gradientBabel = "cN_r3";
export var discoverWorksItem__gradientCaker = "cN_rT";
export var discoverWorksItem__gradientDv = "cN_rZ";
export var discoverWorksItem__gradientImpact = "cN_rP";
export var discoverWorksItem__gradientIx = "cN_rY";
export var discoverWorksItem__gradientMalleni = "cN_rQ";
export var discoverWorksItem__gradientPmk = "cN_rW";
export var discoverWorksItem__gradientPmkDesktop = "cN_rX";
export var discoverWorksItem__gradientRew = "cN_rN";
export var discoverWorksItem__gradientTimebot = "cN_r2";
export var discoverWorksItem__gradientTradeaboat = "cN_rR";
export var discoverWorksItem__gradientTradervs = "cN_rS";
export var discoverWorksItem__gradientVaria = "cN_r1";
export var discoverWorksItem__gradientWowner = "cN_rV";
export var discoverWorksItem__header = "cN_rt";
export var discoverWorksItem__imageHover = "cN_rv";
export var discoverWorksItem__mobileImage = "cN_rH";
export var discoverWorksItem__results = "cN_rz";
export var discoverWorksItem__results_head = "cN_rB";
export var discoverWorksItem__results_num = "cN_rC";
export var discoverWorksItem__results_text = "cN_rD";
export var discoverWorksItem__results_textOnly = "cN_rF";
export var discoverWorksItem__review = "cN_rJ";
export var discoverWorksItem__title = "cN_rx";
export var discoverWorksItem__wrapper = "cN_ry";