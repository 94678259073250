// extracted by mini-css-extract-plugin
export var marketplaceAppDevBenefitsSection = "W_jv";
export var marketplaceAppDevCaseSection = "W_jB";
export var marketplaceAppDevClientQuotes = "W_jD";
export var marketplaceAppDevIndustriesSection = "W_jt";
export var marketplaceAppDevOnlineSection = "W_jy";
export var marketplaceAppDevPracticesSection = "W_jz";
export var marketplaceAppDevPrimeSection = "W_jr";
export var marketplaceAppDevProcessSection = "W_jw";
export var marketplaceAppDevServicesSection = "W_js";
export var marketplaceAppDevTechSection = "W_jx";
export var marketplaceAppExpertiseSection = "W_jC";