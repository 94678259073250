// extracted by mini-css-extract-plugin
export var qaAchievementsSection = "v_cM";
export var qaBenefitsSection = "v_cB";
export var qaBusinessSection = "v_cC";
export var qaCtaSection = "v_cJ";
export var qaCtaSection__cta = "v_cK";
export var qaDomainExpertiseSection = "v_cG";
export var qaResultSection = "v_cF";
export var qaServicesSection = "v_cx";
export var qaTechStackSection = "v_cH";
export var qaTestimonialPost = "v_cD";
export var qaTestingServicesSection = "v_cy";
export var qaVideoClientSection = "v_cN";
export var qaWhenDoYouNeedSection = "v_cz";
export var qaWhyChooseSection = "v_cL";