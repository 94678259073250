// extracted by mini-css-extract-plugin
export var projectsLogo__lazy = "ft_LG";
export var projectsLogo__logo = "ft_LF";
export var projectsLogo__logo_1 = "ft_LH";
export var projectsLogo__logo_2 = "ft_LJ";
export var projectsLogo__logo_3 = "ft_LK";
export var projectsLogo__logo_4 = "ft_LL";
export var projectsLogo__logo_5 = "ft_LM";
export var projectsLogo__logo_6 = "ft_LN";
export var projectsLogo__logo_7 = "ft_LP";
export var projectsLogo__logo_8 = "ft_LQ";
export var projectsLogo__logos = "ft_LD";
export var projectsLogo__wrapper = "ft_LC";