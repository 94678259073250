// extracted by mini-css-extract-plugin
export var socialBlock = "cZ_wv";
export var socialBlock__icon = "cZ_wB";
export var socialBlock__iconWrapper = "cZ_wz";
export var socialBlock__icon_facebook = "cZ_wC";
export var socialBlock__icon_instagram = "cZ_wG";
export var socialBlock__icon_linkedin = "cZ_wD";
export var socialBlock__icon_telegram = "cZ_wH";
export var socialBlock__icon_tiktok = "cZ_wJ";
export var socialBlock__icon_twitter = "cZ_wF";
export var socialBlock__iconsWrapper = "cZ_wy";
export var socialBlock__sprite = "cZ_wx";
export var socialBlock__title = "cZ_ww";