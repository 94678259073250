// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bM_kv";
export var caseStudyBackground__lineColor = "bM_kl";
export var caseStudyHead__imageWrapper = "bM_kj";
export var caseStudyProjectsSection = "bM_kw";
export var caseStudyQuote__bgRing = "bM_kr";
export var caseStudySolution__ring = "bM_kp";
export var caseStudySolution__ringThree = "bM_lr";
export var caseStudySolution__ringTwo = "bM_kq";
export var caseStudyVideo__ring = "bM_kP";
export var caseStudy__bgDark = "bM_kh";
export var caseStudy__bgLight = "bM_kg";
export var caseStudy__bgLightGray = "bM_mk";