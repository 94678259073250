// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bt_kv";
export var caseStudyBackground__lineColor = "bt_kl";
export var caseStudyHead__imageWrapper = "bt_kj";
export var caseStudyProjectsSection = "bt_kw";
export var caseStudyQuote__bgLight = "bt_ks";
export var caseStudyQuote__bgRing = "bt_kr";
export var caseStudySolution__ring = "bt_kp";
export var caseStudyVideo__ring = "bt_kP";
export var caseStudy__bgDark = "bt_kh";
export var caseStudy__bgLight = "bt_kg";