import { toast } from 'react-toastify'

import { StrapiApi } from '~/services/Api'

export const subscribeSubmitFormEmail = (data, reset) =>
  new Promise((resolve, reject) => {
    StrapiApi.createSubscription({
      email: data.email,
    })
      .then((response) => {
        toast.success('Thanks for subscribing!', {
          position: toast.POSITION.TOP_RIGHT,
        })
        reset({ email: '' })
        resolve(response)
      })
      .catch((err) => {
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
        reject(err)
      })
  })
