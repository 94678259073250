// extracted by mini-css-extract-plugin
export var awardCard = "c9_xZ";
export var awardCard__bdg_large = "c9_x3";
export var awardCard__bdg_small = "c9_x4";
export var awardCard__container = "c9_x9";
export var awardCard__description = "c9_x8";
export var awardCard__info = "c9_x5";
export var awardCard__large = "c9_x1";
export var awardCard__link = "c9_x7";
export var awardCard__post = "c9_x0";
export var awardCard__small = "c9_x2";
export var awardCard__title = "c9_x6";