// extracted by mini-css-extract-plugin
export var achievementsSlider__badge_1 = "fs_Lf";
export var achievementsSlider__badge_10 = "fs_Lq";
export var achievementsSlider__badge_11 = "fs_Lr";
export var achievementsSlider__badge_12 = "fs_Ls";
export var achievementsSlider__badge_13 = "fs_Lt";
export var achievementsSlider__badge_14 = "fs_Lv";
export var achievementsSlider__badge_15 = "fs_Lw";
export var achievementsSlider__badge_16 = "fs_Lx";
export var achievementsSlider__badge_17 = "fs_Ly";
export var achievementsSlider__badge_18 = "fs_Lz";
export var achievementsSlider__badge_19 = "fs_LB";
export var achievementsSlider__badge_2 = "fs_Lg";
export var achievementsSlider__badge_3 = "fs_Lh";
export var achievementsSlider__badge_4 = "fs_Lj";
export var achievementsSlider__badge_5 = "fs_Lk";
export var achievementsSlider__badge_6 = "fs_Ll";
export var achievementsSlider__badge_7 = "fs_Lm";
export var achievementsSlider__badge_8 = "fs_Ln";
export var achievementsSlider__badge_9 = "fs_Lp";
export var achievementsSlider__logo = "fs_Ld";
export var achievementsSlider__swiperSliderWrapper = "fs_Lc";