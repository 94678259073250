// extracted by mini-css-extract-plugin
export var aiChat__aiPowered = "b2_nn";
export var aiChat__animate = "b2_nc";
export var aiChat__animate_content = "b2_nd";
export var aiChat__answer = "b2_nk";
export var aiChat__content = "b2_ng";
export var aiChat__icon = "b2_nf";
export var aiChat__loadingState = "b2_nh";
export var aiChat__message = "b2_nb";
export var aiChat__question = "b2_nj";
export var aiChat__sender = "b2_nl";
export var aiChat__senderInfo = "b2_nm";
export var pulseSize = "b2_m0";