// extracted by mini-css-extract-plugin
export var aiChat__aiPowered = "by_lB";
export var aiChat__animate = "by_lp";
export var aiChat__animate_content = "by_lq";
export var aiChat__answer = "by_lx";
export var aiChat__content = "by_ls";
export var aiChat__icon = "by_lr";
export var aiChat__loadingState = "by_lt";
export var aiChat__message = "by_ln";
export var aiChat__question = "by_lw";
export var aiChat__sender = "by_ly";
export var aiChat__senderInfo = "by_lz";
export var pulseSize = "by_lv";