// extracted by mini-css-extract-plugin
export var mvpWebDevBenefitsSection = "F_fN";
export var mvpWebDevBusinessTypeSection = "F_fK";
export var mvpWebDevCaseSection = "F_fR";
export var mvpWebDevClientQuotes = "F_fS";
export var mvpWebDevExpertiseSection = "F_fQ";
export var mvpWebDevIndustriesSection = "F_fJ";
export var mvpWebDevPracticesSection = "F_fP";
export var mvpWebDevPrimeSection = "F_fG";
export var mvpWebDevProcessSection = "F_fL";
export var mvpWebDevServicesSection = "F_fH";
export var mvpWebDevTechStackSection = "F_fM";