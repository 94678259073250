// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bl_kx";
export var caseStudyBackground__bgColor = "bl_km";
export var caseStudyBackground__lineColor = "bl_kn";
export var caseStudyHead = "bl_kz";
export var caseStudyHead__imageAbsolute = "bl_kD";
export var caseStudyHead__imageWrapper = "bl_kl";
export var caseStudyProjectsSection = "bl_ky";
export var caseStudyQuote__bgRing = "bl_kt";
export var caseStudyTechSection = "bl_kF";
export var caseStudy__bgDark = "bl_kk";
export var caseStudy__bgLight = "bl_kj";