// extracted by mini-css-extract-plugin
export var onlineMarketplaceAchievementsSection = "x_dk";
export var onlineMarketplaceCardBorderSection = "x_c3";
export var onlineMarketplaceCardRowIconsSection = "x_c8";
export var onlineMarketplaceClientQuotesSection = "x_dl";
export var onlineMarketplaceCycleDevSection = "x_c4";
export var onlineMarketplaceCycleDevSection__testimonial = "x_c5";
export var onlineMarketplaceDomainExpertiseSection = "x_c6";
export var onlineMarketplaceExceptionSection = "x_c7";
export var onlineMarketplaceFeaturedCaseSection = "x_c9";
export var onlineMarketplaceFeaturesSection = "x_dc";
export var onlineMarketplaceFeaturesSection__box = "x_dd";
export var onlineMarketplaceRequirementSection = "x_df";
export var onlineMarketplaceSuccessStoriesSection = "x_dh";
export var onlineMarketplaceSuccessfulSection = "x_db";
export var onlineMarketplaceTechStackSection = "x_dg";
export var onlineMarketplaceWhyHireSection = "x_dj";