// extracted by mini-css-extract-plugin
export var projectsLogo__lazy = "fv_LL";
export var projectsLogo__logo = "fv_LK";
export var projectsLogo__logo_1 = "fv_LM";
export var projectsLogo__logo_2 = "fv_LN";
export var projectsLogo__logo_3 = "fv_LP";
export var projectsLogo__logo_4 = "fv_LQ";
export var projectsLogo__logo_5 = "fv_LR";
export var projectsLogo__logo_6 = "fv_LS";
export var projectsLogo__logo_7 = "fv_LT";
export var projectsLogo__logo_8 = "fv_LV";
export var projectsLogo__logos = "fv_LJ";
export var projectsLogo__wrapper = "fv_LH";