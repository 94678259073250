// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bm_kx";
export var caseStudyBackground__lineColor = "bm_kn";
export var caseStudyHead__imageWrapper = "bm_kl";
export var caseStudyProjectsSection = "bm_ky";
export var caseStudyQuote__bgLight = "bm_kv";
export var caseStudyQuote__bgRing = "bm_kt";
export var caseStudyQuote__bgRingDark = "bm_kG";
export var caseStudyQuote__bottomVideo = "bm_kH";
export var caseStudySolution__ring = "bm_kr";
export var caseStudySolution__ringTwo = "bm_ks";
export var caseStudyVideo = "bm_kC";
export var caseStudyVideo__ring = "bm_kB";
export var caseStudy__bgDark = "bm_kk";
export var caseStudy__bgLight = "bm_kj";