// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bD_kx";
export var caseStudyBackground__lineColor = "bD_kn";
export var caseStudyHead = "bD_kz";
export var caseStudyHead__imageWrapper = "bD_kl";
export var caseStudyProjectsSection = "bD_ky";
export var caseStudyQuote__bgLight = "bD_kv";
export var caseStudyQuote__bgRing = "bD_kt";
export var caseStudyVideoReview = "bD_kQ";
export var caseStudyVideoReview__bgRing = "bD_kR";
export var caseStudyVideo__ring = "bD_kB";
export var caseStudy__bgDark = "bD_kk";
export var caseStudy__bgLight = "bD_kj";
export var caseStudy__bgLightReverse = "bD_kP";