// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bq_kv";
export var caseStudyBackground__lineColor = "bq_kl";
export var caseStudyHead = "bq_kK";
export var caseStudyHead__imageWrapper = "bq_kj";
export var caseStudyProjectsSection = "bq_kw";
export var caseStudyQuote__bgLight = "bq_ks";
export var caseStudyQuote__bgRing = "bq_kr";
export var caseStudyVideoReview = "bq_kM";
export var caseStudyVideoReview__bgRing = "bq_kN";
export var caseStudyVideo__ring = "bq_kP";
export var caseStudy__bgDark = "bq_kh";
export var caseStudy__bgLight = "bq_kg";
export var caseStudy__bgLightReverse = "bq_kJ";