// extracted by mini-css-extract-plugin
export var reactAppDevBusinessSection = "t_cn";
export var reactAppDevCardBorderIconSection = "t_cm";
export var reactAppDevCaseSection = "t_ct";
export var reactAppDevClientsSection = "t_cw";
export var reactAppDevExpertiseSection = "t_cv";
export var reactAppDevIndustriesSection = "t_cp";
export var reactAppDevPreferencesSection = "t_cs";
export var reactAppDevProcessSection = "t_cq";
export var reactAppDevServicesIconSection = "t_cl";
export var reactAppDevTechStackSection = "t_cr";