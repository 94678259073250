// extracted by mini-css-extract-plugin
export var recentArticles = "cD_qB";
export var recentArticles__category = "cD_qN";
export var recentArticles__img = "cD_qF";
export var recentArticles__link = "cD_qD";
export var recentArticles__post = "cD_qC";
export var recentArticles__postClamp = "cD_qM";
export var recentArticles__postDate = "cD_qH";
export var recentArticles__postDescriprion = "cD_qL";
export var recentArticles__postInfo = "cD_qG";
export var recentArticles__postStats = "cD_qJ";
export var recentArticles__postTitle = "cD_qK";
export var recentArticles__title = "cD_qP";