// extracted by mini-css-extract-plugin
export var servicesToolkitSection = "dl_zt";
export var servicesToolkitSection__card = "dl_zv";
export var servicesToolkitSection__icons = "dl_zw";
export var servicesToolkitSection__serviceToolkitIcon1 = "dl_zy";
export var servicesToolkitSection__serviceToolkitIcon10 = "dl_zK";
export var servicesToolkitSection__serviceToolkitIcon11 = "dl_zL";
export var servicesToolkitSection__serviceToolkitIcon12 = "dl_zM";
export var servicesToolkitSection__serviceToolkitIcon13 = "dl_zN";
export var servicesToolkitSection__serviceToolkitIcon14 = "dl_zP";
export var servicesToolkitSection__serviceToolkitIcon15 = "dl_zQ";
export var servicesToolkitSection__serviceToolkitIcon16 = "dl_zR";
export var servicesToolkitSection__serviceToolkitIcon17 = "dl_zS";
export var servicesToolkitSection__serviceToolkitIcon18 = "dl_zT";
export var servicesToolkitSection__serviceToolkitIcon19 = "dl_zV";
export var servicesToolkitSection__serviceToolkitIcon2 = "dl_zz";
export var servicesToolkitSection__serviceToolkitIcon20 = "dl_zW";
export var servicesToolkitSection__serviceToolkitIcon3 = "dl_zB";
export var servicesToolkitSection__serviceToolkitIcon4 = "dl_zC";
export var servicesToolkitSection__serviceToolkitIcon5 = "dl_zD";
export var servicesToolkitSection__serviceToolkitIcon6 = "dl_zF";
export var servicesToolkitSection__serviceToolkitIcon7 = "dl_zG";
export var servicesToolkitSection__serviceToolkitIcon8 = "dl_zH";
export var servicesToolkitSection__serviceToolkitIcon9 = "dl_zJ";
export var servicesToolkitSection__sprite = "dl_zx";
export var servicesToolkitSection__title = "dl_zX";