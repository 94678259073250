// extracted by mini-css-extract-plugin
export var caseStudyVideoReview = "d6_kQ";
export var caseStudyVideoReview__bgRings = "d6_Jp";
export var caseStudyVideoReview__clientImage = "d6_Jr";
export var caseStudyVideoReview__container = "d6_Jn";
export var caseStudyVideoReview__logo = "d6_JC";
export var caseStudyVideoReview__name = "d6_JD";
export var caseStudyVideoReview__playWrapper = "d6_Jf";
export var caseStudyVideoReview__playWrapper_videoReviewImpact = "d6_Jh";
export var caseStudyVideoReview__playWrapper_videoReviewIx = "d6_Jm";
export var caseStudyVideoReview__playWrapper_videoReviewPmk = "d6_Jk";
export var caseStudyVideoReview__playWrapper_videoReviewRef = "d6_Jl";
export var caseStudyVideoReview__playWrapper_videoReviewTradeaboat = "d6_Jg";
export var caseStudyVideoReview__playWrapper_videoReviewTradervs = "d6_Jj";
export var caseStudyVideoReview__text = "d6_JF";
export var caseStudyVideoReview__title = "d6_Jq";
export var caseStudyVideoReview__videoYoutube = "d6_JB";
export var caseStudyVideoReview__wrapper = "d6_Js";
export var caseStudyVideoReview__wrapper_videoReviewImpact = "d6_Jw";
export var caseStudyVideoReview__wrapper_videoReviewIx = "d6_Jz";
export var caseStudyVideoReview__wrapper_videoReviewPmk = "d6_Jx";
export var caseStudyVideoReview__wrapper_videoReviewRef = "d6_Jy";
export var caseStudyVideoReview__wrapper_videoReviewTradeaboat = "d6_Jt";
export var caseStudyVideoReview__wrapper_videoReviewTradervs = "d6_Jv";