// extracted by mini-css-extract-plugin
export var sprite3DIcons = "cQ_r6";
export var sprite3DIcons__sprite = "cQ_r7";
export var sprite3DIcons__sprite3DIcon_1 = "cQ_r8";
export var sprite3DIcons__sprite3DIcon_10 = "cQ_sk";
export var sprite3DIcons__sprite3DIcon_11 = "cQ_sl";
export var sprite3DIcons__sprite3DIcon_12 = "cQ_sm";
export var sprite3DIcons__sprite3DIcon_13 = "cQ_sn";
export var sprite3DIcons__sprite3DIcon_14 = "cQ_sp";
export var sprite3DIcons__sprite3DIcon_15 = "cQ_sq";
export var sprite3DIcons__sprite3DIcon_16 = "cQ_sr";
export var sprite3DIcons__sprite3DIcon_17 = "cQ_ss";
export var sprite3DIcons__sprite3DIcon_18 = "cQ_st";
export var sprite3DIcons__sprite3DIcon_19 = "cQ_sv";
export var sprite3DIcons__sprite3DIcon_2 = "cQ_r9";
export var sprite3DIcons__sprite3DIcon_20 = "cQ_sw";
export var sprite3DIcons__sprite3DIcon_21 = "cQ_sx";
export var sprite3DIcons__sprite3DIcon_22 = "cQ_sy";
export var sprite3DIcons__sprite3DIcon_3 = "cQ_sb";
export var sprite3DIcons__sprite3DIcon_4 = "cQ_sc";
export var sprite3DIcons__sprite3DIcon_5 = "cQ_sd";
export var sprite3DIcons__sprite3DIcon_6 = "cQ_sf";
export var sprite3DIcons__sprite3DIcon_7 = "cQ_sg";
export var sprite3DIcons__sprite3DIcon_8 = "cQ_sh";
export var sprite3DIcons__sprite3DIcon_9 = "cQ_sj";