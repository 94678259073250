// extracted by mini-css-extract-plugin
export var benefitsSectionMvp = "C_dZ";
export var businessesSectionMvp = "C_dX";
export var clientsMvpSection = "C_d8";
export var devApproachSectionMvp = "C_d0";
export var domainExpertiseMvpSection = "C_dW";
export var launchProductSectionMvp = "C_d1";
export var launchProductSectionMvp__testimonial = "C_d2";
export var ourAchievementsMvp = "C_d7";
export var servicesCardBorderIconMvpSection = "C_dV";
export var servicesDevExpertiseMvp = "C_d3";
export var servicesProcessMvpSection = "C_dY";
export var successStoriesMvpSection = "C_d5";
export var technologiesSectionMvp = "C_d4";
export var whyChooseMvpSection = "C_d6";