// extracted by mini-css-extract-plugin
export var homeWebDevProcess = "cn_pG";
export var homeWebDevProcess__between = "cn_pH";
export var homeWebDevProcess__contentFive = "cn_pR";
export var homeWebDevProcess__contentFour = "cn_pQ";
export var homeWebDevProcess__contentOne = "cn_pM";
export var homeWebDevProcess__contentThree = "cn_pP";
export var homeWebDevProcess__contentTwo = "cn_pN";
export var homeWebDevProcess__description = "cn_pL";
export var homeWebDevProcess__image = "cn_pJ";
export var homeWebDevProcess__title = "cn_pK";