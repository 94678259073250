// extracted by mini-css-extract-plugin
export var africarColor = "dc_yy";
export var ammoColor = "dc_yf";
export var babelColor = "dc_yx";
export var cakerColor = "dc_yj";
export var chatticColor = "dc_yg";
export var danColor = "dc_yh";
export var dumaColor = "dc_yr";
export var dvColor = "dc_yB";
export var impactColor = "dc_ym";
export var ixColor = "dc_yC";
export var malleniColor = "dc_yl";
export var pmkColor = "dc_yw";
export var refColor = "dc_ys";
export var rewColor = "dc_yt";
export var timebotColor = "dc_yp";
export var tradeaboatColor = "dc_yq";
export var tradervsColor = "dc_yn";
export var variaColor = "dc_yk";
export var wownerColor = "dc_yv";
export var xpcColor = "dc_yz";