// extracted by mini-css-extract-plugin
export var cloudAppDevBenefitsSection = "Q_hN";
export var cloudAppDevBusinessSection = "Q_hH";
export var cloudAppDevCasesSection = "Q_hP";
export var cloudAppDevIndustriesSection = "Q_hJ";
export var cloudAppDevModelSection = "Q_hG";
export var cloudAppDevOurAchievementsSection = "Q_hM";
export var cloudAppDevPrimeSection = "Q_hD";
export var cloudAppDevProcessSection = "Q_hK";
export var cloudAppDevQuotesSection = "Q_hQ";
export var cloudAppDevServicesSection = "Q_hF";
export var cloudAppDevTechStackSection = "Q_hL";