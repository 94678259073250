// extracted by mini-css-extract-plugin
export var africarColor = "dd_yM";
export var ammoColor = "dd_ys";
export var babelColor = "dd_yL";
export var cakerColor = "dd_yw";
export var chatticColor = "dd_yt";
export var danColor = "dd_yv";
export var dumaColor = "dd_yF";
export var dvColor = "dd_yP";
export var impactColor = "dd_yz";
export var ixColor = "dd_yQ";
export var malleniColor = "dd_yy";
export var pmkColor = "dd_yK";
export var refColor = "dd_yG";
export var rewColor = "dd_yH";
export var timebotColor = "dd_yC";
export var tradeaboatColor = "dd_yD";
export var tradervsColor = "dd_yB";
export var variaColor = "dd_yx";
export var wownerColor = "dd_yJ";
export var xpcColor = "dd_yN";