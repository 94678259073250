// extracted by mini-css-extract-plugin
export var chatgptCasesExpertiseSection = "N_hh";
export var chatgptCasesSection = "N_hl";
export var chatgptDevExpertiseSection = "N_hm";
export var chatgptIndustriesSection = "N_hf";
export var chatgptPrimeSection = "N_hc";
export var chatgptProcessSection = "N_hj";
export var chatgptQuotesSection = "N_hp";
export var chatgptServicesSection = "N_hd";
export var chatgptTechStackSection = "N_hk";
export var chatgptTrustedSection = "N_hg";
export var chatgptWhyChooseSection = "N_hn";