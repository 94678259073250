// extracted by mini-css-extract-plugin
export var devopsAchievementsSection = "K_gR";
export var devopsBenefitSection = "K_gG";
export var devopsBusinessSection = "K_gD";
export var devopsDomainSection = "K_gN";
export var devopsExpertiseSection = "K_gM";
export var devopsProvideSection = "K_gH";
export var devopsResultsSection = "K_gJ";
export var devopsSuccessStoriesSection = "K_gP";
export var devopsTestimonialPostSection = "K_gK";
export var devopsToolkitSection = "K_gL";
export var devopsVideoReviewSection = "K_gS";
export var devopsWhyChooseSection = "K_gQ";
export var devopsWhyNeedSection = "K_gF";