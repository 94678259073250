// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bJ_kv";
export var caseStudyBackground__lineColor = "bJ_kl";
export var caseStudyHead__imageWrapper = "bJ_kj";
export var caseStudyProjectsSection = "bJ_kw";
export var caseStudyQuote__bgLight = "bJ_ks";
export var caseStudyQuote__bgRing = "bJ_kr";
export var caseStudyQuote__bottomVideo = "bJ_kL";
export var caseStudyVideo = "bJ_kR";
export var caseStudyVideo__ring = "bJ_kP";
export var caseStudy__bgDark = "bJ_kh";
export var caseStudy__bgLight = "bJ_kg";