// extracted by mini-css-extract-plugin
export var recentArticles = "cl_pH";
export var recentArticles__category = "cl_pT";
export var recentArticles__img = "cl_pL";
export var recentArticles__link = "cl_pK";
export var recentArticles__post = "cl_pJ";
export var recentArticles__postClamp = "cl_pS";
export var recentArticles__postDate = "cl_pN";
export var recentArticles__postDescriprion = "cl_pR";
export var recentArticles__postInfo = "cl_pM";
export var recentArticles__postStats = "cl_pP";
export var recentArticles__postTitle = "cl_pQ";
export var recentArticles__title = "cl_pV";