import Button from '~/components/buttons/Button'
import AnimatedInput from '~/components/forms/fields/text-inputs/AnimatedInput'
import { FormProvider } from '~/components/forms/wrappers/FormProvider'
import { useSubscribeFormEmail } from '~/hooks'

import 'react-toastify/dist/ReactToastify.min.css'

import * as containerStyles from './SubscribeArticleSmall.module.scss'

const SubscribeArticleSmall = () => {
  const { methods, handleSubmit, onSubmit } = useSubscribeFormEmail()

  return (
    <div className={containerStyles.articleSubscribeSmall}>
      <div className={containerStyles.articleSubscribeSmall__head}>
        Never miss a resource
      </div>
      <div className={containerStyles.articleSubscribeSmall__subhead}>
        All you have to do is subscribe to our newsletter!
      </div>
      <FormProvider
        id="subscribe-article-form-small"
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        className={containerStyles.articleSubscribeSmall__form}
      >
        <div className={containerStyles.articleSubscribeSmall__formWrapper}>
          <AnimatedInput
            type="email"
            name="email"
            label="Your E-mail *"
            data-id="subscribeArticleSidebarEmail"
            dataIdError="subscribeArticleSidebarEmailError"
          />
          <Button
            type="submit"
            name="Subscribe article"
            dataId="subscribeArticleButton"
            id="subscribeArticleSidebarButton"
            customClassName={containerStyles.articleSubscribeSmall__formBtn}
          >
            <span />
          </Button>
        </div>
      </FormProvider>
    </div>
  )
}

export default SubscribeArticleSmall
