// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bK_kx";
export var caseStudyBackground__lineColor = "bK_kn";
export var caseStudyHead__imageWrapper = "bK_kl";
export var caseStudyProjectsSection = "bK_ky";
export var caseStudyQuote__bgLight = "bK_kv";
export var caseStudyQuote__bgRing = "bK_kt";
export var caseStudyQuote__bottomVideo = "bK_kH";
export var caseStudyVideo = "bK_kC";
export var caseStudyVideo__ring = "bK_kB";
export var caseStudy__bgDark = "bK_kk";
export var caseStudy__bgLight = "bK_kj";