// extracted by mini-css-extract-plugin
export var aiAwardsSection = "R_hY";
export var aiBenefitsSection = "R_hR";
export var aiBusinessSection = "R_hV";
export var aiCasesSection = "R_hW";
export var aiCompanySection = "R_hX";
export var aiIndustriesSection = "R_hQ";
export var aiPracticesSection = "R_hS";
export var aiPrimeSection = "R_hN";
export var aiProcessSection = "R_hT";
export var aiQuoteSection = "R_hZ";
export var aiServicesIconSection = "R_hP";