// extracted by mini-css-extract-plugin
export var discoverWorksItem = "cK_rB";
export var discoverWorksItem__arrowLink = "cK_rP";
export var discoverWorksItem__bg = "cK_rF";
export var discoverWorksItem__clientImg = "cK_rS";
export var discoverWorksItem__clientName = "cK_rT";
export var discoverWorksItem__clientPosition = "cK_rV";
export var discoverWorksItem__gradientAfricar = "cK_r7";
export var discoverWorksItem__gradientBabel = "cK_sb";
export var discoverWorksItem__gradientCaker = "cK_r1";
export var discoverWorksItem__gradientDv = "cK_r6";
export var discoverWorksItem__gradientImpact = "cK_rX";
export var discoverWorksItem__gradientIx = "cK_r5";
export var discoverWorksItem__gradientMalleni = "cK_rY";
export var discoverWorksItem__gradientPmk = "cK_r3";
export var discoverWorksItem__gradientPmkDesktop = "cK_r4";
export var discoverWorksItem__gradientRew = "cK_rW";
export var discoverWorksItem__gradientTimebot = "cK_r9";
export var discoverWorksItem__gradientTradeaboat = "cK_rZ";
export var discoverWorksItem__gradientTradervs = "cK_r0";
export var discoverWorksItem__gradientVaria = "cK_r8";
export var discoverWorksItem__gradientWowner = "cK_r2";
export var discoverWorksItem__header = "cK_rC";
export var discoverWorksItem__imageHover = "cK_rD";
export var discoverWorksItem__mobileImage = "cK_rQ";
export var discoverWorksItem__results = "cK_rJ";
export var discoverWorksItem__results_head = "cK_rK";
export var discoverWorksItem__results_num = "cK_rL";
export var discoverWorksItem__results_text = "cK_rM";
export var discoverWorksItem__results_textOnly = "cK_rN";
export var discoverWorksItem__review = "cK_rR";
export var discoverWorksItem__title = "cK_rG";
export var discoverWorksItem__wrapper = "cK_rH";