// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bM_kx";
export var caseStudyBackground__lineColor = "bM_kn";
export var caseStudyHead = "bM_kz";
export var caseStudyHead__imageWrapper = "bM_kl";
export var caseStudyProjectsSection = "bM_ky";
export var caseStudyQuote__bgRing = "bM_kt";
export var caseStudyVideo__ring = "bM_kB";
export var caseStudy__bgDark = "bM_kk";
export var caseStudy__bgDarkReverse = "bM_l2";
export var caseStudy__bgLight = "bM_kj";