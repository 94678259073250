// extracted by mini-css-extract-plugin
export var servicesToolkitSection = "dp_zX";
export var servicesToolkitSection__card = "dp_zY";
export var servicesToolkitSection__icons = "dp_zZ";
export var servicesToolkitSection__serviceToolkitIcon1 = "dp_z1";
export var servicesToolkitSection__serviceToolkitIcon10 = "dp_Bb";
export var servicesToolkitSection__serviceToolkitIcon11 = "dp_Bc";
export var servicesToolkitSection__serviceToolkitIcon12 = "dp_Bd";
export var servicesToolkitSection__serviceToolkitIcon13 = "dp_Bf";
export var servicesToolkitSection__serviceToolkitIcon14 = "dp_Bg";
export var servicesToolkitSection__serviceToolkitIcon15 = "dp_Bh";
export var servicesToolkitSection__serviceToolkitIcon16 = "dp_Bj";
export var servicesToolkitSection__serviceToolkitIcon17 = "dp_Bk";
export var servicesToolkitSection__serviceToolkitIcon18 = "dp_Bl";
export var servicesToolkitSection__serviceToolkitIcon19 = "dp_Bm";
export var servicesToolkitSection__serviceToolkitIcon2 = "dp_z2";
export var servicesToolkitSection__serviceToolkitIcon20 = "dp_Bn";
export var servicesToolkitSection__serviceToolkitIcon3 = "dp_z3";
export var servicesToolkitSection__serviceToolkitIcon4 = "dp_z4";
export var servicesToolkitSection__serviceToolkitIcon5 = "dp_z5";
export var servicesToolkitSection__serviceToolkitIcon6 = "dp_z6";
export var servicesToolkitSection__serviceToolkitIcon7 = "dp_z7";
export var servicesToolkitSection__serviceToolkitIcon8 = "dp_z8";
export var servicesToolkitSection__serviceToolkitIcon9 = "dp_z9";
export var servicesToolkitSection__sprite = "dp_z0";
export var servicesToolkitSection__title = "dp_Bp";