import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'
import kebabCase from 'lodash/kebabCase'

import { fileToImageLikeData } from '~/utils'

import * as containerStyles from './ArticleAuthorPrime.module.scss'

type ArticleAuthorPrimeProps = {
  authors: Queries.STRAPI_AUTHOR[]
}

const ArticleAuthorPrime = ({ authors }: ArticleAuthorPrimeProps) => (
  <>
    {authors.map(({ id, slug, avatar, name, position, description }, index) => (
      <div className={containerStyles.articleAuthorPrime} key={id}>
        <Link
          key={id}
          to={`/blog/author/${kebabCase(slug || '')}/`}
          className={containerStyles.articleAuthorPrime__link}
        >
          <div className={containerStyles.articleAuthorPrime__photo}>
            <GatsbyImage
              image={
                getImage(
                  fileToImageLikeData(avatar!.localFile),
                ) as IGatsbyImageData
              }
              alt={`${name} ${position} | Codica`}
              title={`${name} ${position}`}
              loading="lazy"
            />
          </div>
          <div>
            <div className={containerStyles.articleAuthorPrime__name}>
              {name}
            </div>
            <div className={containerStyles.articleAuthorPrime__position}>
              {position}
            </div>
          </div>
        </Link>

        {index === 0 && description && (
          <div className={containerStyles.articleAuthorPrime__description}>
            {description}
          </div>
        )}
      </div>
    ))}
  </>
)

export default ArticleAuthorPrime
