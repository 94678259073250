// extracted by mini-css-extract-plugin
export var succesStoriesCard = "fG_MK";
export var succesStoriesCardRow = "fG_MV";
export var succesStoriesCard__bottomWrapper = "fG_MQ";
export var succesStoriesCard__category = "fG_MS";
export var succesStoriesCard__categoryWrapper = "fG_MR";
export var succesStoriesCard__description = "fG_MP";
export var succesStoriesCard__image = "fG_MM";
export var succesStoriesCard__locaation = "fG_MT";
export var succesStoriesCard__logosWrapper = "fG_MN";
export var succesStoriesCard__title = "fG_ML";