import { lazy, Suspense } from 'react'

import HeadSection from '~/components/blocks/HeadSection'
import RecentArticles from '~/components/blocks/RecentArticles'
import SubscribeArticleSmall from '~/components/forms/SubscribeArticleSmall'
import SubscribeFormLarge from '~/components/forms/SubscribeFormLarge'
import MainLayout from '~/components/layouts/MainLayout'
import EmptyFallback from '~/components/render-kit/EmptyFallback'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import LazyHydrate from '~/components/shared/LazyHydrate'
import ReCaptchaProvider from '~/components/shared/ReCaptchaProvider'
import MyErrorBoundary from '~/containers/MyErrorBoundary'
import { BlogArticlePageQueryResult } from '~/templates/blog/Article'
import { PagePropsWithContext } from '~/types/helper'
import { BlogPageContext } from '~/types/page-contex'
import { cx } from '~/utils'

import * as containerStyles from './Article.module.scss'
import ArticleAuthorInfo from './components/ArticleAuthorInfo'
import ArticleAuthorPrime from './components/ArticleAuthorPrime'
import ArticleComments from './components/ArticleComments'
import ArticleHtmlToReact from './components/ArticleHtmlToReact'
import ArticlePrimeSection from './components/ArticlePrimeSection'
import ArticleRatingSection from './components/ArticleRatingSection'
import ArticleRelatedPosts from './components/ArticleRelatedPosts'
import ArticleSideNavigation from './components/ArticleSideNavigation'
import ArticleSocialShare from './components/ArticleSocialShare'

const ContactForm = lazy(() => import('~/components/forms/ContactForm'))
const ArticleFaqSection = lazy(() => import('./components/ArticleFaqSection'))

const ArticlePage = ({
  data,
}: PagePropsWithContext<BlogArticlePageQueryResult, BlogPageContext>) => {
  const {
    strapi_id,
    url,
    title,
    meta_title,
    authors,
    category,
    rating,
    views,
    lead_form,
    articlefaqs,
    body,
  } = data.strapiArticle as Queries.STRAPI_ARTICLE

  const sortedArticleFaqs = (articlefaqs as Queries.STRAPI_ARTICLEFAQ[])?.sort(
    (a, b) => a.createdAt!.localeCompare(b.createdAt as string),
  )

  return (
    <MainLayout isStaticChat isProgress scrollTop>
      <div className={containerStyles.article}>
        <div className="container container-md">
          <div className="row">
            <div className="col-12">
              <ArticlePrimeSection
                data={data}
                stats={body?.data?.childMarkdownRemark?.timeToRead || 0}
                views={views}
                title={title}
                url={url}
                category={category}
                articleId={strapi_id}
                authors={authors as Queries.STRAPI_AUTHOR[]}
              />
            </div>

            <div className={cx(containerStyles.article__lg, 'col-12 col-lg-8')}>
              <div
                className={cx(
                  containerStyles.article__content,
                  'article-content',
                )}
              >
                <ArticleHtmlToReact
                  key={strapi_id}
                  source={body?.data?.childMarkdownRemark?.rehypeHtml || ''}
                  images={body?.medias || undefined}
                />

                {lead_form && (
                  <div className={containerStyles.article__contactForm}>
                    <RenderWhenVisible
                      withSuspense
                      fallback={<EmptyFallback height={527} />}
                    >
                      <ReCaptchaProvider>
                        <ContactForm title="Let’s turn your idea into a successful product!" />
                      </ReCaptchaProvider>
                    </RenderWhenVisible>
                  </div>
                )}

                <ArticleSocialShare url={url} title={meta_title} />
              </div>
            </div>

            <div className={cx(containerStyles.article__sm, 'col-12 col-lg-4')}>
              <div className={containerStyles.article__author}>
                <ArticleAuthorPrime
                  authors={authors as Queries.STRAPI_AUTHOR[]}
                />
              </div>

              <ReCaptchaProvider>
                <SubscribeArticleSmall />
              </ReCaptchaProvider>

              <ArticleSideNavigation />
            </div>
          </div>

          <LazyHydrate whenVisible>
            <div className="row">
              <div className="col-12 col-lg-8">
                {!!sortedArticleFaqs?.length && (
                  <Suspense>
                    <ArticleFaqSection dataContent={sortedArticleFaqs} />
                  </Suspense>
                )}

                <ArticleAuthorInfo
                  authors={authors as Queries.STRAPI_AUTHOR[]}
                />

                <MyErrorBoundary>
                  <ArticleRatingSection
                    strapiId={strapi_id}
                    customRating={rating || 0}
                  />
                </MyErrorBoundary>

                <ArticleComments articleId={strapi_id} />
              </div>
            </div>
          </LazyHydrate>
        </div>

        <div className={containerStyles.article__postsSection}>
          <HeadSection position="left" title="Related posts" />
          <ArticleRelatedPosts data={data} />
        </div>

        <div className="container container-md">
          <SubscribeFormLarge
            title="Stay up-to-date and never miss a resource"
            description="Subscribe to Codica newsletter"
          />
        </div>

        <div className={containerStyles.article__postsSection}>
          <HeadSection position="left" title="Latest posts" />
          <RecentArticles data={data} />
        </div>
      </div>
    </MainLayout>
  )
}

export default ArticlePage
