// extracted by mini-css-extract-plugin
export var websiteRedesignCardBusinessSection = "p_bC";
export var websiteRedesignCaseSection = "p_bM";
export var websiteRedesignExpertiseSection = "p_bH";
export var websiteRedesignIndustriesSection = "p_bD";
export var websiteRedesignPracticesSection = "p_bK";
export var websiteRedesignPreferencesSection = "p_bG";
export var websiteRedesignPrimeSection = "p_bz";
export var websiteRedesignProcessSection = "p_bF";
export var websiteRedesignServicesSection = "p_bB";
export var websiteRedesignTestimonialPost = "p_bJ";
export var websiteRedesignWebDesignCasesSection = "p_bL";