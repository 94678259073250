// extracted by mini-css-extract-plugin
export var productDiscoverTestimonialSection = "y_dq";
export var productDiscoveryAchievementsSection = "y_dC";
export var productDiscoveryBenefitsSection = "y_dp";
export var productDiscoveryCaseSection = "y_dx";
export var productDiscoveryClientSection = "y_dD";
export var productDiscoveryIndustriesSection = "y_dn";
export var productDiscoveryOverviewSection = "y_dv";
export var productDiscoveryProcessSection = "y_dt";
export var productDiscoveryResultsSection = "y_dy";
export var productDiscoveryRolesSection = "y_dw";
export var productDiscoveryServicesSection = "y_dm";
export var productDiscoveryStagesSection = "y_dr";
export var productDiscoverySuccessStoriesSection = "y_dz";
export var productDiscoveryWhyChooseSection = "y_dB";
export var productDiscroveryImportanceSection = "y_ds";