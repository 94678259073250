// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bY_kv";
export var caseStudyBackground__lineColor = "bY_kl";
export var caseStudyHead__imageAbsolute = "bY_kX";
export var caseStudyHead__imageWrapper = "bY_kj";
export var caseStudyProjectsSection = "bY_kw";
export var caseStudyQuote__bgLight = "bY_ks";
export var caseStudyQuote__bgRing = "bY_kr";
export var caseStudySolution__ring = "bY_kp";
export var caseStudyTech = "bY_kt";
export var caseStudy__bgDark = "bY_kh";
export var caseStudy__bgLight = "bY_kg";