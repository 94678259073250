// extracted by mini-css-extract-plugin
export var approachInfoRoundBlock__between = "cZ_wG";
export var approachInfoRoundBlock__card = "cZ_wF";
export var approachInfoRoundBlock__contentFive = "cZ_wR";
export var approachInfoRoundBlock__contentFour = "cZ_wQ";
export var approachInfoRoundBlock__contentOne = "cZ_wM";
export var approachInfoRoundBlock__contentSix = "cZ_wS";
export var approachInfoRoundBlock__contentThree = "cZ_wP";
export var approachInfoRoundBlock__contentTwo = "cZ_wN";
export var approachInfoRoundBlock__description = "cZ_wL";
export var approachInfoRoundBlock__icon = "cZ_wJ";
export var approachInfoRoundBlock__imageDesktop = "cZ_wH";
export var approachInfoRoundBlock__title = "cZ_wK";