// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b0_kx";
export var caseStudyBackground__lineColor = "b0_kn";
export var caseStudyHead = "b0_kz";
export var caseStudyHead__imageWrapper = "b0_kl";
export var caseStudyProjectsSection = "b0_ky";
export var caseStudyQuote__bgLight = "b0_kv";
export var caseStudyQuote__bgRing = "b0_kt";
export var caseStudyVideoReview = "b0_kQ";
export var caseStudyVideoReview__bgRing = "b0_kR";
export var caseStudyVideo__ring = "b0_kB";
export var caseStudy__bgDark = "b0_kk";
export var caseStudy__bgLight = "b0_kj";
export var caseStudy__bgLightReverse = "b0_kP";