// extracted by mini-css-extract-plugin
export var achievementsSlider__badge_1 = "fr_Lc";
export var achievementsSlider__badge_10 = "fr_Ln";
export var achievementsSlider__badge_11 = "fr_Lp";
export var achievementsSlider__badge_12 = "fr_Lq";
export var achievementsSlider__badge_13 = "fr_Lr";
export var achievementsSlider__badge_14 = "fr_Ls";
export var achievementsSlider__badge_15 = "fr_Lt";
export var achievementsSlider__badge_16 = "fr_Lv";
export var achievementsSlider__badge_17 = "fr_Lw";
export var achievementsSlider__badge_18 = "fr_Lx";
export var achievementsSlider__badge_19 = "fr_Ly";
export var achievementsSlider__badge_2 = "fr_Ld";
export var achievementsSlider__badge_3 = "fr_Lf";
export var achievementsSlider__badge_4 = "fr_Lg";
export var achievementsSlider__badge_5 = "fr_Lh";
export var achievementsSlider__badge_6 = "fr_Lj";
export var achievementsSlider__badge_7 = "fr_Lk";
export var achievementsSlider__badge_8 = "fr_Ll";
export var achievementsSlider__badge_9 = "fr_Lm";
export var achievementsSlider__logo = "fr_Lb";
export var achievementsSlider__swiperSliderWrapper = "fr_K9";