// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bn_kx";
export var caseStudyBackground__bgColor = "bn_km";
export var caseStudyBackground__lineColor = "bn_kn";
export var caseStudyCta__bgColor = "bn_kJ";
export var caseStudyHead__imageWrapper = "bn_kl";
export var caseStudyProjectsSection = "bn_ky";
export var caseStudyQuote__bgLight = "bn_kv";
export var caseStudyQuote__bgRing = "bn_kt";
export var caseStudyTechSection = "bn_kF";
export var caseStudyVideo = "bn_kC";
export var caseStudyVideo__ring = "bn_kB";
export var caseStudy__bgDark = "bn_kk";
export var caseStudy__bgLight = "bn_kj";