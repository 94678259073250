// extracted by mini-css-extract-plugin
export var mobileAppCardTechStack = "dt_BN";
export var mobileAppCardTechStack__card = "dt_BP";
export var mobileAppCardTechStack__description = "dt_BR";
export var mobileAppCardTechStack__iconsMobileDev = "dt_BS";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechJS = "dt_BX";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechKotlin = "dt_BV";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechReact = "dt_BW";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechSwift = "dt_BT";
export var mobileAppCardTechStack__iconsMobileDev_mobileAppDevTechTS = "dt_BY";
export var mobileAppCardTechStack__title = "dt_BQ";