// extracted by mini-css-extract-plugin
export var pwaBenefitWebsiteSection = "t_cy";
export var pwaCardRowIconsSection = "t_cC";
export var pwaCycleDevelopmentSection = "t_cz";
export var pwaCycleDevelopmentSection__testimonial = "t_cB";
export var pwaDomainExpertiseSection = "t_cG";
export var pwaFeaturedCaseSection = "t_cH";
export var pwaServiceAchievementsSection = "t_cM";
export var pwaServiceClientsSection = "t_cN";
export var pwaServicesCompaniesSection = "t_cD";
export var pwaServicesCycleDevelopmentSection = "t_cF";
export var pwaSuccessStoriesSection = "t_cK";
export var pwaTechStackSection = "t_cJ";
export var pwaWhyChooseSection = "t_cL";