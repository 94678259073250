// extracted by mini-css-extract-plugin
export var servicesTechStack__item = "f4_PB";
export var servicesTechStack__itemWrapper = "f4_Pz";
export var servicesTechStack__name = "f4_PC";
export var servicesTechStack__name_chromedevtools = "f4_PL";
export var servicesTechStack__name_digitalocean = "f4_PJ";
export var servicesTechStack__name_elasticsearch = "f4_PG";
export var servicesTechStack__name_gitlab = "f4_PK";
export var servicesTechStack__name_googleCloudPlatform = "f4_PN";
export var servicesTechStack__name_googleDocs = "f4_PM";
export var servicesTechStack__name_googleanalytics = "f4_PD";
export var servicesTechStack__name_prometheus = "f4_PF";
export var servicesTechStack__name_ror = "f4_PH";