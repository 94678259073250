// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bN_kx";
export var caseStudyBackground__lineColor = "bN_kn";
export var caseStudyHead__imageWrapper = "bN_kl";
export var caseStudyProjectsSection = "bN_ky";
export var caseStudyQuote__bgQuote = "bN_l3";
export var caseStudyQuote__bgRing = "bN_kt";
export var caseStudyVideo__ring = "bN_kB";
export var caseStudy__bgDark = "bN_kk";
export var caseStudy__bgLight = "bN_kj";
export var caseStudy__bgLightGray = "bN_l1";