// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bZ_kx";
export var caseStudyBackground__lineColor = "bZ_kn";
export var caseStudyHead__imageAbsolute = "bZ_kD";
export var caseStudyHead__imageWrapper = "bZ_kl";
export var caseStudyProjectsSection = "bZ_ky";
export var caseStudyQuote__bgLight = "bZ_kv";
export var caseStudyQuote__bgRing = "bZ_kt";
export var caseStudySolution__ring = "bZ_kr";
export var caseStudyTech = "bZ_kw";
export var caseStudy__bgDark = "bZ_kk";
export var caseStudy__bgLight = "bZ_kj";