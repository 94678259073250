// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bz_kv";
export var caseStudyBackground__lineColor = "bz_kl";
export var caseStudyCta__bgColor = "bz_kQ";
export var caseStudyHead__imageWrapper = "bz_kj";
export var caseStudyProjectsSection = "bz_kw";
export var caseStudyQuote__bgDark = "bz_lq";
export var caseStudyQuote__bgLight = "bz_ks";
export var caseStudyQuote__bgRing = "bz_kr";
export var caseStudySolution__ring = "bz_kp";
export var caseStudySolution__ringThree = "bz_lr";
export var caseStudySolution__ringTwo = "bz_kq";
export var caseStudyVideo = "bz_kR";
export var caseStudyVideo__ring = "bz_kP";
export var caseStudy__bgDark = "bz_kh";
export var caseStudy__bgLight = "bz_kg";