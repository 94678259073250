// extracted by mini-css-extract-plugin
export var homeWebDevProcess = "cd_nY";
export var homeWebDevProcess__between = "cd_nZ";
export var homeWebDevProcess__contentFive = "cd_n7";
export var homeWebDevProcess__contentFour = "cd_n6";
export var homeWebDevProcess__contentOne = "cd_n3";
export var homeWebDevProcess__contentThree = "cd_n5";
export var homeWebDevProcess__contentTwo = "cd_n4";
export var homeWebDevProcess__description = "cd_n2";
export var homeWebDevProcess__image = "cd_n0";
export var homeWebDevProcess__title = "cd_n1";