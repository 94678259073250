// extracted by mini-css-extract-plugin
export var crossPlatformBenefitsSection = "N_g9";
export var crossPlatformBusinessSection = "N_hc";
export var crossPlatformCasesSection = "N_hk";
export var crossPlatformExpertiseSection = "N_hh";
export var crossPlatformIndustriesSection = "N_hb";
export var crossPlatformPreferencesSection = "N_hj";
export var crossPlatformProcessSection = "N_hd";
export var crossPlatformQuotesSection = "N_hl";
export var crossPlatformServicesSection = "N_g8";
export var crossPlatformTechStackSection = "N_hf";
export var crossPlatformTechStackSection__callToAction = "N_hg";