// extracted by mini-css-extract-plugin
export var ammoCaseStudyVideo = "bX_m0";
export var caseContactFormSection = "bX_kx";
export var caseSolutionRing = "bX_m1";
export var caseStudyBackground__bgColor = "bX_km";
export var caseStudyBackground__lineColor = "bX_kn";
export var caseStudyCta__bgColor = "bX_kJ";
export var caseStudyHead = "bX_kz";
export var caseStudyHead__imageWrapper = "bX_kl";
export var caseStudyProjectsSection = "bX_ky";
export var caseStudyQuote__dark = "bX_mX";
export var caseStudyQuote__light = "bX_mY";
export var caseStudySolution__ringOne = "bX_mZ";
export var caseStudySolution__ringTwo = "bX_ks";
export var caseStudyTechSection = "bX_kF";
export var caseStudyVideo__ring = "bX_kB";
export var caseStudy__bgDark = "bX_kk";
export var caseStudy__bgLight = "bX_kj";