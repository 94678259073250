// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bk_kv";
export var caseStudyBackground__lineColor = "bk_kl";
export var caseStudyHead = "bk_kK";
export var caseStudyHead__imageWrapper = "bk_kj";
export var caseStudyProjectsSection = "bk_kw";
export var caseStudyQuote__bgRing = "bk_kr";
export var caseStudyQuote__bottomVideo = "bk_kL";
export var caseStudyVideoReview = "bk_kM";
export var caseStudyVideoReview__bgRing = "bk_kN";
export var caseStudyVideo__ring = "bk_kP";
export var caseStudy__bgDark = "bk_kh";
export var caseStudy__bgLight = "bk_kg";
export var caseStudy__bgLightReverse = "bk_kJ";