// extracted by mini-css-extract-plugin
export var benefitsCustomSoftwareSection = "M_gX";
export var businessCustomSoftwareSection = "M_gV";
export var caseStudyCustomSoftwareSection = "M_gZ";
export var casesCustomSoftwareSection = "M_g1";
export var clientQuotesCustomSoftwareSection = "M_g7";
export var expertiseCustomSoftwareSection = "M_g3";
export var industriesCustomSoftwareSection = "M_gT";
export var ourAchievementsCustomSoftwareSection = "M_g6";
export var processCustomSoftwareSection = "M_gW";
export var requirementCustomSoftwareSection = "M_g0";
export var servicesCustomSoftwareSection = "M_gS";
export var techStackCustomSoftwareSection = "M_gY";
export var testimonialsCustomSoftwareSection = "M_g4";
export var testimonialsCustomSoftwareSection__testimonial = "M_g5";
export var whyChooseCustomSoftwareSection = "M_g2";