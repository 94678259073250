// extracted by mini-css-extract-plugin
export var companyInfo__card = "cx_ql";
export var companyInfo__cardText = "cx_qn";
export var companyInfo__cardTitle = "cx_qm";
export var companyInfo__description = "cx_qk";
export var companyInfo__flex = "cx_qt";
export var companyInfo__head = "cx_qs";
export var companyInfo__headSm = "cx_qr";
export var companyInfo__title = "cx_qj";
export var companyInfo__topWrapper = "cx_qq";
export var companyInfo__wrapper = "cx_qp";