import { Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'
import { useState } from 'react'

import { AllStrapiTag } from '~/types/graphql'

import * as containerStyles from './NavigationTag.module.scss'

export interface NavigationTagProps {
  data: AllStrapiTag
  showMore?: boolean
}

const NavigationTag = ({ data, showMore = false }: NavigationTagProps) => {
  const [show, setShow] = useState(showMore)

  const toggleShowMore = () => {
    setShow(!show)
  }

  const sortedTags = data.allStrapiTag.edges.filter(
    (item) =>
      item.node?.articles?.length &&
      item.node?.articles?.some((article) => article!.active),
  )

  const displayedTags = show ? sortedTags : sortedTags.slice(0, 7)

  return (
    <div className="col-12">
      <div className={containerStyles.navigationTag__head}>Tags</div>
      <nav className={containerStyles.navigationTag}>
        {displayedTags.map((item) => (
          <Link
            key={item.node.url}
            to={`/blog/tag/${kebabCase(item.node.url || '')}/`}
            activeClassName={containerStyles.navigationTag__linkActive}
            className={containerStyles.navigationTag__link}
          >
            {item.node.name}
          </Link>
        ))}

        {!show && (
          <button
            type="button"
            id="hideBtn"
            name="Show more tags"
            aria-label="Show more tags"
            onClick={toggleShowMore}
            className={containerStyles.navigationTag__showMore}
          >
            Show more tags
          </button>
        )}
      </nav>
    </div>
  )
}

export default NavigationTag
