// extracted by mini-css-extract-plugin
export var customEnterpriseDevBenefitsSection = "P_hv";
export var customEnterpriseDevCallToActionSection = "P_hq";
export var customEnterpriseDevCaseSection = "P_hB";
export var customEnterpriseDevCostSection = "P_hw";
export var customEnterpriseDevExperienceSection = "P_hz";
export var customEnterpriseDevExpertiseSection = "P_hy";
export var customEnterpriseDevIndustriesSection = "P_hp";
export var customEnterpriseDevPracticesSection = "P_hx";
export var customEnterpriseDevPrimeSection = "P_hm";
export var customEnterpriseDevProcessSection = "P_hs";
export var customEnterpriseDevQuotesSection = "P_hC";
export var customEnterpriseDevServicesSection = "P_hn";
export var customEnterpriseDevSolutionSection = "P_hr";
export var customEnterpriseDevTechSection = "P_ht";