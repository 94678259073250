// extracted by mini-css-extract-plugin
export var multiVendorBenefitsSection = "B_d3";
export var multiVendorBusinessesSection = "B_dX";
export var multiVendorClinetsSection = "B_d7";
export var multiVendorDevExpertiseSection = "B_d4";
export var multiVendorDiscoverWorksSection = "B_d2";
export var multiVendorFeaturesSection = "B_dW";
export var multiVendorIdeaToLifeSection = "B_d1";
export var multiVendorIndustriesSection = "B_dY";
export var multiVendorOurAchievementsSection = "B_d6";
export var multiVendorProcessSection = "B_dZ";
export var multiVendorServicesSection = "B_dV";
export var multiVendorSuccessStoriesSection = "B_d5";
export var multiVendorTechStackSection = "B_d0";