// extracted by mini-css-extract-plugin
export var chatgptCasesExpertiseSection = "R_hW";
export var chatgptCasesSection = "R_hZ";
export var chatgptDevExpertiseSection = "R_h0";
export var chatgptIndustriesSection = "R_hT";
export var chatgptPrimeSection = "R_hR";
export var chatgptProcessSection = "R_hX";
export var chatgptQuotesSection = "R_h2";
export var chatgptServicesSection = "R_hS";
export var chatgptTechStackSection = "R_hY";
export var chatgptTrustedSection = "R_hV";
export var chatgptWhyChooseSection = "R_h1";