// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bl_kv";
export var caseStudyBackground__bgColor = "bl_kk";
export var caseStudyBackground__lineColor = "bl_kl";
export var caseStudyCta__bgColor = "bl_kQ";
export var caseStudyHead__imageWrapper = "bl_kj";
export var caseStudyProjectsSection = "bl_kw";
export var caseStudyQuote__bgLight = "bl_ks";
export var caseStudyQuote__bgRing = "bl_kr";
export var caseStudyTechSection = "bl_kS";
export var caseStudyVideo = "bl_kR";
export var caseStudyVideo__ring = "bl_kP";
export var caseStudy__bgDark = "bl_kh";
export var caseStudy__bgLight = "bl_kg";