// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bk_kx";
export var caseStudyBackground__lineColor = "bk_kn";
export var caseStudyHead = "bk_kz";
export var caseStudyHead__imageWrapper = "bk_kl";
export var caseStudyProjectsSection = "bk_ky";
export var caseStudyQuote__bgLight = "bk_kv";
export var caseStudyQuote__bgRing = "bk_kt";
export var caseStudyVideo = "bk_kC";
export var caseStudyVideo__ring = "bk_kB";
export var caseStudy__bgDark = "bk_kk";
export var caseStudy__bgLight = "bk_kj";