// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bj_kx";
export var caseStudyBackground__lineColor = "bj_kn";
export var caseStudyHead = "bj_kz";
export var caseStudyHead__imageWrapper = "bj_kl";
export var caseStudyProjectsSection = "bj_ky";
export var caseStudyQuote__bgLight = "bj_kv";
export var caseStudyQuote__bgRing = "bj_kt";
export var caseStudyVideo__ring = "bj_kB";
export var caseStudy__bgDark = "bj_kk";
export var caseStudy__bgLight = "bj_kj";