// extracted by mini-css-extract-plugin
export var approachInfoRoundBlock__between = "c2_wW";
export var approachInfoRoundBlock__card = "c2_wV";
export var approachInfoRoundBlock__contentFive = "c2_w5";
export var approachInfoRoundBlock__contentFour = "c2_w4";
export var approachInfoRoundBlock__contentOne = "c2_w1";
export var approachInfoRoundBlock__contentSix = "c2_w6";
export var approachInfoRoundBlock__contentThree = "c2_w3";
export var approachInfoRoundBlock__contentTwo = "c2_w2";
export var approachInfoRoundBlock__description = "c2_w0";
export var approachInfoRoundBlock__icon = "c2_wY";
export var approachInfoRoundBlock__imageDesktop = "c2_wX";
export var approachInfoRoundBlock__title = "c2_wZ";