// extracted by mini-css-extract-plugin
export var succesStoriesCard = "fD_MC";
export var succesStoriesCardRow = "fD_MN";
export var succesStoriesCard__bottomWrapper = "fD_MJ";
export var succesStoriesCard__category = "fD_ML";
export var succesStoriesCard__categoryWrapper = "fD_MK";
export var succesStoriesCard__description = "fD_MH";
export var succesStoriesCard__image = "fD_MF";
export var succesStoriesCard__locaation = "fD_MM";
export var succesStoriesCard__logosWrapper = "fD_MG";
export var succesStoriesCard__title = "fD_MD";