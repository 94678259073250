// extracted by mini-css-extract-plugin
export var aiChat__preview = "b5_nz";
export var aiChat__previewButton = "b5_nK";
export var aiChat__previewButtonWrapper = "b5_nJ";
export var aiChat__previewContainer = "b5_nB";
export var aiChat__previewDescr = "b5_nH";
export var aiChat__previewFixedBottom = "b5_nL";
export var aiChat__previewLogo = "b5_nF";
export var aiChat__previewLogoWrapper = "b5_nC";
export var aiChat__previewTitle = "b5_nG";
export var pulseLightRed = "b5_nD";