// extracted by mini-css-extract-plugin
export var ammoCaseStudyVideo = "bH_mc";
export var caseContactFormSection = "bH_kv";
export var caseSolutionRing = "bH_md";
export var caseStudyBackground__bgColor = "bH_kk";
export var caseStudyBackground__lineColor = "bH_kl";
export var caseStudyCta__bgColor = "bH_kQ";
export var caseStudyHead = "bH_kK";
export var caseStudyHead__imageWrapper = "bH_kj";
export var caseStudyProjectsSection = "bH_kw";
export var caseStudyQuote__dark = "bH_l8";
export var caseStudyQuote__light = "bH_l9";
export var caseStudySolution__ringOne = "bH_mb";
export var caseStudySolution__ringTwo = "bH_kq";
export var caseStudyTechSection = "bH_kS";
export var caseStudyVideo__ring = "bH_kP";
export var caseStudy__bgDark = "bH_kh";
export var caseStudy__bgLight = "bH_kg";