// extracted by mini-css-extract-plugin
export var webAppDevBenefits = "p_bC";
export var webAppDevBusinesses = "p_bB";
export var webAppDevCaseSection = "p_bK";
export var webAppDevClientQuotes = "p_bM";
export var webAppDevCostSection = "p_bG";
export var webAppDevExperienceSection = "p_bJ";
export var webAppDevExpertiseSection = "p_bL";
export var webAppDevPracticesSection = "p_bH";
export var webAppDevProcessSection = "p_bD";
export var webAppDevServiceIndustries = "p_bz";
export var webAppDevServiceSection = "p_by";
export var webAppDevTechStackSection = "p_bF";