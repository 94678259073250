// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bw_kv";
export var caseStudyBackground__lineColor = "bw_kl";
export var caseStudyHead = "bw_kK";
export var caseStudyHead__imageWrapper = "bw_kj";
export var caseStudyProjectsSection = "bw_kw";
export var caseStudyQuote__bgRing = "bw_kr";
export var caseStudyVideo__ring = "bw_kP";
export var caseStudy__bgDark = "bw_kh";
export var caseStudy__bgDarkReverse = "bw_k7";
export var caseStudy__bgLight = "bw_kg";