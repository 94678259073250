// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bm_kv";
export var caseStudyBackground__lineColor = "bm_kl";
export var caseStudyHead = "bm_kK";
export var caseStudyHead__imageWrapper = "bm_kj";
export var caseStudyProjectsSection = "bm_kw";
export var caseStudyQuote__bgLight = "bm_ks";
export var caseStudyQuote__bgRing = "bm_kr";
export var caseStudyVideo = "bm_kR";
export var caseStudyVideo__ring = "bm_kP";
export var caseStudy__bgDark = "bm_kh";
export var caseStudy__bgLight = "bm_kg";