// extracted by mini-css-extract-plugin
export var servicesDevExpertiseCompany = "dd_yD";
export var servicesDevExpertiseCompany__card = "dd_yF";
export var servicesDevExpertiseCompany__emoji = "dd_yH";
export var servicesDevExpertiseCompany__maxWidth140 = "dd_yK";
export var servicesDevExpertiseCompany__maxWidth180 = "dd_yL";
export var servicesDevExpertiseCompany__maxWidth190 = "dd_yM";
export var servicesDevExpertiseCompany__maxWidth270 = "dd_yN";
export var servicesDevExpertiseCompany__maxWidth310 = "dd_yP";
export var servicesDevExpertiseCompany__text = "dd_yJ";
export var servicesDevExpertiseCompany__title = "dd_yG";