// extracted by mini-css-extract-plugin
export var pressCard__date = "bZ_mX";
export var pressCard__description = "bZ_mW";
export var pressCard__image_large = "bZ_mN";
export var pressCard__image_small = "bZ_mP";
export var pressCard__info = "bZ_mQ";
export var pressCard__info_large = "bZ_mR";
export var pressCard__info_small = "bZ_mS";
export var pressCard__large = "bZ_mY";
export var pressCard__link = "bZ_mM";
export var pressCard__post = "bZ_mL";
export var pressCard__small = "bZ_mK";
export var pressCard__source = "bZ_mT";
export var pressCard__title = "bZ_mV";