import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import kebabCase from 'lodash/kebabCase'

import SEO from '~/components/shared/Seo'
import {
  AllStrapiArticle,
  AllStrapiCategory,
  StrapiCategory,
} from '~/types/graphql'
import { PagePropsWithContext, WithRequired } from '~/types/helper'
import { fileToImageLikeData } from '~/utils'
import CategoriesView from '~/views/Blog/views/Categories.view'

export type CategoriesQueryResult = StrapiCategory &
  AllStrapiArticle &
  AllStrapiCategory

export const Head = ({
  data,
  pageContext,
}: PagePropsWithContext<CategoriesQueryResult>) => {
  const { category, humanPageNumber, previousPagePath, nextPagePath } =
    pageContext

  const strapiCategory = data.strapiCategory as WithRequired<
    Queries.STRAPI_CATEGORY,
    'name' | 'og_image'
  > & {
    title: string
    description: string
  }

  const { name, og_image } = strapiCategory
  const metaData = strapiCategory as Record<string, any>

  const seoNumPage = humanPageNumber > 1 ? ` - Page ${humanPageNumber}` : ''

  metaData.url =
    humanPageNumber === 1
      ? `https://www.codica.com/blog/categories/${kebabCase(name)}/`
      : `https://www.codica.com/blog/categories/${kebabCase(
          name,
        )}/page/${humanPageNumber}/`

  const seoData = {
    'marketplace development': data.strapiCategory,
    'saas development': data.strapiCategory,
    'mvp development': data.strapiCategory,
    'product guides': data.strapiCategory,
    'company news': data.strapiCategory,
    technology: data.strapiCategory,
  }

  const categorySeo = seoData[category.toLowerCase()]

  return (
    <>
      {previousPagePath && (
        <link rel="prev" href={`https://www.codica.com${previousPagePath}`} />
      )}
      {nextPagePath && (
        <link rel="next" href={`https://www.codica.com${nextPagePath}`} />
      )}

      <SEO
        {...categorySeo}
        title={`${metaData.meta_title}${seoNumPage}`}
        description={`${metaData.description}${seoNumPage}`}
        urlImage={getSrc(fileToImageLikeData(og_image.localFile))}
        meta={[
          {
            name: 'robots',
            content:
              'max-image-preview:large, max-snippet:-1, max-video-preview:-1',
          },
        ]}
        isBreadcrumbs
        secondLevel="Blog"
        secondLevelPath="/blog/"
        current={`${metaData.title}`}
        currentPath={`/blog/categories/${kebabCase(metaData.title || '')}/`}
      />
    </>
  )
}

const Categories = ({
  data,
  pageContext,
}: PagePropsWithContext<CategoriesQueryResult>) => (
  <CategoriesView data={data} pageContext={pageContext} />
)

export default Categories

export const pageQuery = graphql`
  query ArticleCategory(
    $category: String
    $published: [Boolean]
    $skip: Int!
    $limit: Int!
  ) {
    strapiCategory(name: { eq: $category }) {
      name
      meta_title
      title: meta_title
      description: meta_description
      og_image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      categorybanner {
        link
        title
        active
        description
        read_time
        post_date
        main_img {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      categoriescases {
        link
        title
        active
        description
        categories {
          name
        }
        main_img {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }

    allStrapiCategory(sort: { order: ASC }) {
      edges {
        node {
          name
          nav_name
          id
        }
      }
    }

    allStrapiArticle(
      sort: { modified_date: DESC }
      filter: {
        active: { eq: true }
        category: { name: { eq: $category } }
        published: { in: $published }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          authors {
            name
            position
            avatar {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    quality: 100
                  )
                }
              }
            }
          }
          publication_date(formatString: "DD MMM YYYY")
          modified_date(formatString: "DD MMM YYYY")
          id
          url
          body {
            data {
              childMarkdownRemark {
                timeToRead
              }
            }
          }
          strapi_id
          views
          preview_image_alt
          category {
            name
          }
          preview_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
          }
          title
          meta_description
          published
        }
      }
    }
  }
`
