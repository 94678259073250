// extracted by mini-css-extract-plugin
export var reactAppDevBusinessSection = "z_dx";
export var reactAppDevCardBorderIconSection = "z_dw";
export var reactAppDevCaseSection = "z_dD";
export var reactAppDevClientsSection = "z_dG";
export var reactAppDevExpertiseSection = "z_dF";
export var reactAppDevIndustriesSection = "z_dy";
export var reactAppDevPreferencesSection = "z_dC";
export var reactAppDevProcessSection = "z_dz";
export var reactAppDevServicesIconSection = "z_dv";
export var reactAppDevTechStackSection = "z_dB";