// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bq_kx";
export var caseStudyBackground__lineColor = "bq_kn";
export var caseStudyHead = "bq_kz";
export var caseStudyHead__imageWrapper = "bq_kl";
export var caseStudyProjectsSection = "bq_ky";
export var caseStudyQuote__bgRing = "bq_kt";
export var caseStudyQuote__bottomVideo = "bq_kH";
export var caseStudyVideoReview = "bq_kQ";
export var caseStudyVideoReview__bgRing = "bq_kR";
export var caseStudyVideo__ring = "bq_kB";
export var caseStudy__bgDark = "bq_kk";
export var caseStudy__bgLight = "bq_kj";
export var caseStudy__bgLightReverse = "bq_kP";