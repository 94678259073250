// extracted by mini-css-extract-plugin
export var eMarketplaceDevBenefitsSection = "L_gN";
export var eMarketplaceDevBusinessSection = "L_gH";
export var eMarketplaceDevCardBrdrIconSection = "L_gF";
export var eMarketplaceDevCaseSection = "L_gP";
export var eMarketplaceDevClientQuotesSection = "L_gR";
export var eMarketplaceDevExpertiseSection = "L_gL";
export var eMarketplaceDevFeaturesSection = "L_gG";
export var eMarketplaceDevIndustriesSection = "L_gJ";
export var eMarketplaceDevOurAchievementsSection = "L_gQ";
export var eMarketplaceDevProcessSection = "L_gK";
export var eMarketplaceDevServicesIconSection = "L_gD";
export var eMarketplaceDevTechStackSection = "L_gM";