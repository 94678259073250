// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bC_kx";
export var caseStudyBackground__lineColor = "bC_kn";
export var caseStudyCta__bgColor = "bC_kJ";
export var caseStudyHead__imageWrapper = "bC_kl";
export var caseStudyProjectsSection = "bC_ky";
export var caseStudyQuote__bgDark = "bC_lP";
export var caseStudyQuote__bgLight = "bC_kv";
export var caseStudyQuote__bgRing = "bC_kt";
export var caseStudySolution__ring = "bC_kr";
export var caseStudySolution__ringThree = "bC_lQ";
export var caseStudySolution__ringTwo = "bC_ks";
export var caseStudyVideo = "bC_kC";
export var caseStudyVideo__ring = "bC_kB";
export var caseStudy__bgDark = "bC_kk";
export var caseStudy__bgLight = "bC_kj";