// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bR_kv";
export var caseStudyBackground__lineColor = "bR_kl";
export var caseStudyHead__imageWrapper = "bR_kj";
export var caseStudyProjectsSection = "bR_kw";
export var caseStudyQuote__bgQuote = "bR_mt";
export var caseStudyQuote__bgRing = "bR_kr";
export var caseStudyVideo__ring = "bR_kP";
export var caseStudy__bgDark = "bR_kh";
export var caseStudy__bgLight = "bR_kg";
export var caseStudy__bgLightGray = "bR_mk";