// extracted by mini-css-extract-plugin
export var recentPost = "bS_ml";
export var recentPost__button = "bS_my";
export var recentPost__postCard = "bS_mm";
export var recentPost__postCategory = "bS_mx";
export var recentPost__postClamp = "bS_mw";
export var recentPost__postDate = "bS_mr";
export var recentPost__postDescriprion = "bS_mv";
export var recentPost__postImage = "bS_mp";
export var recentPost__postInfo = "bS_mq";
export var recentPost__postLink = "bS_mn";
export var recentPost__postStats = "bS_ms";
export var recentPost__postTitle = "bS_mt";
export var recentPost__title = "bS_mz";