// extracted by mini-css-extract-plugin
export var cloudAppDevBenefitsSection = "P_hz";
export var cloudAppDevBusinessSection = "P_ht";
export var cloudAppDevCasesSection = "P_hB";
export var cloudAppDevIndustriesSection = "P_hv";
export var cloudAppDevModelSection = "P_hs";
export var cloudAppDevOurAchievementsSection = "P_hy";
export var cloudAppDevPrimeSection = "P_hq";
export var cloudAppDevProcessSection = "P_hw";
export var cloudAppDevQuotesSection = "P_hC";
export var cloudAppDevServicesSection = "P_hr";
export var cloudAppDevTechStackSection = "P_hx";