// extracted by mini-css-extract-plugin
export var caseStudyVideoReview = "fd_kM";
export var caseStudyVideoReview__bgRings = "fd_JH";
export var caseStudyVideoReview__clientImage = "fd_JK";
export var caseStudyVideoReview__container = "fd_JG";
export var caseStudyVideoReview__logo = "fd_JV";
export var caseStudyVideoReview__name = "fd_JW";
export var caseStudyVideoReview__playWrapper = "fd_Jx";
export var caseStudyVideoReview__playWrapper_videoReviewImpact = "fd_Jz";
export var caseStudyVideoReview__playWrapper_videoReviewIx = "fd_JF";
export var caseStudyVideoReview__playWrapper_videoReviewPmk = "fd_JC";
export var caseStudyVideoReview__playWrapper_videoReviewRef = "fd_JD";
export var caseStudyVideoReview__playWrapper_videoReviewTradeaboat = "fd_Jy";
export var caseStudyVideoReview__playWrapper_videoReviewTradervs = "fd_JB";
export var caseStudyVideoReview__text = "fd_JX";
export var caseStudyVideoReview__title = "fd_JJ";
export var caseStudyVideoReview__videoYoutube = "fd_JT";
export var caseStudyVideoReview__wrapper = "fd_JL";
export var caseStudyVideoReview__wrapper_videoReviewImpact = "fd_JP";
export var caseStudyVideoReview__wrapper_videoReviewIx = "fd_JS";
export var caseStudyVideoReview__wrapper_videoReviewPmk = "fd_JQ";
export var caseStudyVideoReview__wrapper_videoReviewRef = "fd_JR";
export var caseStudyVideoReview__wrapper_videoReviewTradeaboat = "fd_JM";
export var caseStudyVideoReview__wrapper_videoReviewTradervs = "fd_JN";