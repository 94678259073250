import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Breadcrumbs from '~/components/breadcrumbs/Breadcrumbs'

import AIChatClear from '../AIChatClear'

import * as containerStyles from './AIChatNav.module.scss'

const AIChatNav = ({ handleClearChat, isChatStarted }) => {
  const breakpoints = useBreakpoint()

  return (
    <div className={containerStyles.aiChat__navBox}>
      <Breadcrumbs current="AI Chat" currentPath="/ai-chat/" />
      {breakpoints.md && (
        <AIChatClear onClear={handleClearChat} disabled={!isChatStarted} />
      )}
    </div>
  )
}

export default AIChatNav
