// extracted by mini-css-extract-plugin
export var mvpAppDevBenefitsSection = "z_dK";
export var mvpAppDevCaseSection = "z_dS";
export var mvpAppDevChoiceSection = "z_dQ";
export var mvpAppDevClientQuotes = "z_dT";
export var mvpAppDevCostSection = "z_dN";
export var mvpAppDevExpertiseSection = "z_dR";
export var mvpAppDevIndustriesSection = "z_dH";
export var mvpAppDevPracticesSection = "z_dP";
export var mvpAppDevPrimeSection = "z_dF";
export var mvpAppDevProcessSection = "z_dL";
export var mvpAppDevServicesSection = "z_dG";
export var mvpAppDevTechSection = "z_dM";
export var mvpAppDevTypesSection = "z_dJ";