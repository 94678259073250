// extracted by mini-css-extract-plugin
export var clientQuotesMobileDev = "D_fF";
export var devBenefitBussinesMobileDev = "D_fB";
export var domainExpertiseMobileDev = "D_fw";
export var mobileAppDevTechnologies = "D_fs";
export var mobileAppDevTechnologies__wrapper = "D_ft";
export var ourKeyPrinciplesMobileDev = "D_fz";
export var serviceMobileTestimonialPost = "D_fy";
export var servicesCardBorderIconMobileDevSection = "D_fq";
export var servicesCardWithTopBorderSectionMobileApp = "D_fr";
export var servicesProcessMobileDevSection = "D_fx";
export var successStoriesMobileSection = "D_fD";
export var weBuildProjectsDifferentSection = "D_fv";
export var whyChooseServicesMobile = "D_fC";