// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bp_kv";
export var caseStudyBackground__lineColor = "bp_kl";
export var caseStudyHead__imageWrapper = "bp_kj";
export var caseStudyProjectsSection = "bp_kw";
export var caseStudyQuote__bgLight = "bp_ks";
export var caseStudyQuote__bgRing = "bp_kr";
export var caseStudyQuote__bgRingDark = "bp_kW";
export var caseStudyQuote__bottomVideo = "bp_kL";
export var caseStudySolution__ring = "bp_kp";
export var caseStudySolution__ringTwo = "bp_kq";
export var caseStudyVideo = "bp_kR";
export var caseStudyVideo__ring = "bp_kP";
export var caseStudy__bgDark = "bp_kh";
export var caseStudy__bgLight = "bp_kg";