// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bP_kx";
export var caseStudyBackground__bgColor = "bP_km";
export var caseStudyBackground__lineColor = "bP_kn";
export var caseStudyHead__imageAbsolute = "bP_kD";
export var caseStudyHead__imageWrapper = "bP_kl";
export var caseStudyProjectsSection = "bP_ky";
export var caseStudyQuote__bgRing = "bP_kt";
export var caseStudyTech = "bP_kw";
export var caseStudyVideo = "bP_kC";
export var caseStudyVideo__ring = "bP_kB";
export var caseStudy__bgDark = "bP_kk";
export var caseStudy__bgLight = "bP_kj";