// extracted by mini-css-extract-plugin
export var toolsHelpingUsSection__image = "c4_xf";
export var toolsHelpingUsSection__logo = "c4_xg";
export var toolsHelpingUsSection__techStack = "c4_xj";
export var toolsHelpingUsSection__techStackTitle = "c4_xh";
export var toolsHelpingUsSection__techStackWrapper = "c4_xs";
export var toolsHelpingUsSection__techStackWrapper_axure = "c4_xz";
export var toolsHelpingUsSection__techStackWrapper_figma = "c4_xB";
export var toolsHelpingUsSection__techStackWrapper_github = "c4_xx";
export var toolsHelpingUsSection__techStackWrapper_gitlab = "c4_xw";
export var toolsHelpingUsSection__techStackWrapper_jira = "c4_xv";
export var toolsHelpingUsSection__techStackWrapper_slack = "c4_xy";
export var toolsHelpingUsSection__techStackWrapper_trello = "c4_xt";
export var toolsHelpingUsSection__techStack_axure = "c4_xl";
export var toolsHelpingUsSection__techStack_figma = "c4_xn";
export var toolsHelpingUsSection__techStack_github = "c4_xm";
export var toolsHelpingUsSection__techStack_gitlab = "c4_xr";
export var toolsHelpingUsSection__techStack_jira = "c4_xp";
export var toolsHelpingUsSection__techStack_slack = "c4_xk";
export var toolsHelpingUsSection__techStack_trello = "c4_xq";
export var toolsHelpingUsSection__wrapper = "c4_xd";