// extracted by mini-css-extract-plugin
export var webAppDevBenefits = "n_bp";
export var webAppDevBusinesses = "n_bn";
export var webAppDevCaseSection = "n_bw";
export var webAppDevClientQuotes = "n_by";
export var webAppDevCostSection = "n_bs";
export var webAppDevExperienceSection = "n_bv";
export var webAppDevExpertiseSection = "n_bx";
export var webAppDevPracticesSection = "n_bt";
export var webAppDevProcessSection = "n_bq";
export var webAppDevServiceIndustries = "n_bm";
export var webAppDevServiceSection = "n_bl";
export var webAppDevTechStackSection = "n_br";