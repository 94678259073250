import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { kebabCase } from 'lodash'

import { fileToImageLikeData } from '~/utils'

import * as containerStyles from './AuthorsPreview.module.scss'

const AuthorsPreview = ({ data }) => {
  const currentSlug = data.strapiAuthor!.slug
  const authorsView = ['dmytro', 'oleksandra', 'pavlo', 'evan']

  if (
    authorsView.includes(
      data.allStrapiAuthor.edges.find(
        (author) => author.node.slug === currentSlug,
      )?.node.slug,
    )
  ) {
    authorsView.push('natalya')
  }

  const filteredAuthors = data.allStrapiAuthor.edges
    .filter((author) => {
      const slug = author.node.slug.toLowerCase()
      return authorsView.includes(slug) && slug !== currentSlug
    })
    .sort((a, b) => {
      const activeArticlesA = a.node.articles.filter(
        (article) => article.active,
      ).length
      const activeArticlesB = b.node.articles.filter(
        (article) => article.active,
      ).length
      return activeArticlesB - activeArticlesA
    })

  return (
    <div className="container container-md">
      <div className="row">
        <div className="col-12">
          <div className={containerStyles.authorsPreview__headerSection}>
            Codica authors
          </div>
        </div>

        {filteredAuthors.map((author) => {
          const { slug, name, position, avatar } = author.node

          const image = getImage(fileToImageLikeData(avatar.localFile))
          const imageTitle = `${author.node.name} ${author.node.position} | Codica`

          const activeArticles = author.node.articles.filter(
            (article) => article.active,
          )

          return (
            <div className="col-12 col-sm-6 col-lg-3" key={slug}>
              <Link
                to={`/blog/author/${kebabCase(slug || '')}/`}
                className={containerStyles.authorsPreview__card}
              >
                <div className={containerStyles.authorsPreview__photo}>
                  <GatsbyImage
                    image={image!}
                    alt={imageTitle}
                    title={imageTitle}
                    objectFit="contain"
                  />
                </div>
                <div className={containerStyles.authorsPreview__name}>
                  {name}
                </div>
                <div className={containerStyles.authorsPreview__position}>
                  {position}
                </div>
                <div className={containerStyles.authorsPreview__articles}>
                  {activeArticles.length}
                  <span className="ml5">
                    {activeArticles.length === 1 ? 'article' : 'articles'}
                  </span>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AuthorsPreview
