// extracted by mini-css-extract-plugin
export var recentPost = "bD_lJ";
export var recentPost__button = "bD_lW";
export var recentPost__postCard = "bD_lK";
export var recentPost__postCategory = "bD_lV";
export var recentPost__postClamp = "bD_lT";
export var recentPost__postDate = "bD_lP";
export var recentPost__postDescriprion = "bD_lS";
export var recentPost__postImage = "bD_lM";
export var recentPost__postInfo = "bD_lN";
export var recentPost__postLink = "bD_lL";
export var recentPost__postStats = "bD_lQ";
export var recentPost__postTitle = "bD_lR";
export var recentPost__title = "bD_lX";