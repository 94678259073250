// extracted by mini-css-extract-plugin
export var chatGptExpertiseCases__between = "dC_DQ";
export var chatGptExpertiseCases__card = "dC_DV";
export var chatGptExpertiseCases__contentAll = "dC_D0";
export var chatGptExpertiseCases__contentOne = "dC_DY";
export var chatGptExpertiseCases__contentTwo = "dC_DZ";
export var chatGptExpertiseCases__description = "dC_DT";
export var chatGptExpertiseCases__imageDesktop = "dC_DR";
export var chatGptExpertiseCases__title = "dC_DS";
export var chatGptExpertiseCases__wrapperLeft = "dC_DW";
export var chatGptExpertiseCases__wrapperRight = "dC_DX";