// extracted by mini-css-extract-plugin
export var crossPlatformBenefitsSection = "S_h1";
export var crossPlatformBusinessSection = "S_h3";
export var crossPlatformCasesSection = "S_h9";
export var crossPlatformExpertiseSection = "S_h7";
export var crossPlatformIndustriesSection = "S_h2";
export var crossPlatformPreferencesSection = "S_h8";
export var crossPlatformProcessSection = "S_h4";
export var crossPlatformQuotesSection = "S_jb";
export var crossPlatformServicesSection = "S_h0";
export var crossPlatformTechStackSection = "S_h5";
export var crossPlatformTechStackSection__callToAction = "S_h6";