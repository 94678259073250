// extracted by mini-css-extract-plugin
export var pressCard__date = "cf_pm";
export var pressCard__description = "cf_pl";
export var pressCard__image_large = "cf_pc";
export var pressCard__image_small = "cf_pd";
export var pressCard__info = "cf_pf";
export var pressCard__info_large = "cf_pg";
export var pressCard__info_small = "cf_ph";
export var pressCard__large = "cf_pn";
export var pressCard__link = "cf_pb";
export var pressCard__post = "cf_n9";
export var pressCard__small = "cf_n8";
export var pressCard__source = "cf_pj";
export var pressCard__title = "cf_pk";