// extracted by mini-css-extract-plugin
export var marketplaceAppDevBenefitsSection = "H_fZ";
export var marketplaceAppDevCaseSection = "H_f4";
export var marketplaceAppDevClientQuotes = "H_f6";
export var marketplaceAppDevIndustriesSection = "H_fY";
export var marketplaceAppDevOnlineSection = "H_f2";
export var marketplaceAppDevPracticesSection = "H_f3";
export var marketplaceAppDevPrimeSection = "H_fW";
export var marketplaceAppDevProcessSection = "H_f0";
export var marketplaceAppDevServicesSection = "H_fX";
export var marketplaceAppDevTechSection = "H_f1";
export var marketplaceAppExpertiseSection = "H_f5";