// extracted by mini-css-extract-plugin
export var benefitsSectionMvp = "C_fd";
export var businessesSectionMvp = "C_fb";
export var clientsMvpSection = "C_fp";
export var devApproachSectionMvp = "C_ff";
export var domainExpertiseMvpSection = "C_d9";
export var launchProductSectionMvp = "C_fg";
export var launchProductSectionMvp__testimonial = "C_fh";
export var ourAchievementsMvp = "C_fn";
export var servicesCardBorderIconMvpSection = "C_d8";
export var servicesDevExpertiseMvp = "C_fj";
export var servicesProcessMvpSection = "C_fc";
export var successStoriesMvpSection = "C_fl";
export var technologiesSectionMvp = "C_fk";
export var whyChooseMvpSection = "C_fm";