// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bL_kx";
export var caseStudyBackground__lineColor = "bL_kn";
export var caseStudyHead__imageWrapper = "bL_kl";
export var caseStudyProjectsSection = "bL_ky";
export var caseStudyQuote__bgRing = "bL_kt";
export var caseStudySolution__ring = "bL_kr";
export var caseStudySolution__ringThree = "bL_lQ";
export var caseStudySolution__ringTwo = "bL_ks";
export var caseStudyVideo__ring = "bL_kB";
export var caseStudy__bgDark = "bL_kk";
export var caseStudy__bgLight = "bL_kj";
export var caseStudy__bgLightGray = "bL_l1";