// extracted by mini-css-extract-plugin
export var customEnterpriseDevBenefitsSection = "H_gf";
export var customEnterpriseDevCallToActionSection = "H_f9";
export var customEnterpriseDevCaseSection = "H_gl";
export var customEnterpriseDevCostSection = "H_gg";
export var customEnterpriseDevExperienceSection = "H_gk";
export var customEnterpriseDevExpertiseSection = "H_gj";
export var customEnterpriseDevIndustriesSection = "H_f8";
export var customEnterpriseDevPracticesSection = "H_gh";
export var customEnterpriseDevPrimeSection = "H_f6";
export var customEnterpriseDevProcessSection = "H_gc";
export var customEnterpriseDevQuotesSection = "H_gm";
export var customEnterpriseDevServicesSection = "H_f7";
export var customEnterpriseDevSolutionSection = "H_gb";
export var customEnterpriseDevTechSection = "H_gd";