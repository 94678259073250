// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bP_kv";
export var caseStudyBackground__lineColor = "bP_kl";
export var caseStudyHead = "bP_kK";
export var caseStudyHead__imageWrapper = "bP_kj";
export var caseStudyProjectsSection = "bP_kw";
export var caseStudyQuote__bgLight = "bP_ks";
export var caseStudyQuote__bgRing = "bP_kr";
export var caseStudyVideoReview = "bP_kM";
export var caseStudyVideoReview__bgRing = "bP_kN";
export var caseStudyVideo__ring = "bP_kP";
export var caseStudy__bgDark = "bP_kh";
export var caseStudy__bgLight = "bP_kg";
export var caseStudy__bgLightReverse = "bP_kJ";