// extracted by mini-css-extract-plugin
export var authorHead__aboutAuthorDescription = "dZ_Hn";
export var authorHead__aboutAuthorTitle = "dZ_Hm";
export var authorHead__aboutAuthorWrapper = "dZ_Hl";
export var authorHead__description = "dZ_Hj";
export var authorHead__flexWrapper = "dZ_Hd";
export var authorHead__name = "dZ_Hg";
export var authorHead__photo = "dZ_Hf";
export var authorHead__position = "dZ_Hh";
export var authorHead__socialWrapper = "dZ_Hk";
export var authorHead__wrapper = "dZ_Hc";