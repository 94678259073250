// extracted by mini-css-extract-plugin
export var insuranceBenefitsSection = "J_gr";
export var insuranceBusinessesSection = "J_gp";
export var insuranceCaseStudySection = "J_gt";
export var insuranceClientQuotesSection = "J_gC";
export var insuranceDevExpertiseSection = "J_gy";
export var insuranceDevServicesIconSection = "J_gn";
export var insuranceOurAchievementsSection = "J_gB";
export var insuranceProcessSection = "J_gq";
export var insuranceRequirementsSection = "J_gv";
export var insuranceSkilledSection = "J_gz";
export var insuranceSuccessStoriesSection = "J_gw";
export var insuranceTechStackSection = "J_gs";
export var insuranceWhyHireSection = "J_gx";