// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bs_kv";
export var caseStudyBackground__bgColor = "bs_kk";
export var caseStudyBackground__lineColor = "bs_kl";
export var caseStudyHead = "bs_kK";
export var caseStudyHead__imageAbsolute = "bs_kX";
export var caseStudyHead__imageWrapper = "bs_kj";
export var caseStudyProjectsSection = "bs_kw";
export var caseStudyQuote__bgRing = "bs_kr";
export var caseStudyTechSection = "bs_kS";
export var caseStudy__bgDark = "bs_kh";
export var caseStudy__bgLight = "bs_kg";