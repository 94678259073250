// extracted by mini-css-extract-plugin
export var clientQuotesMobileDev = "G_fV";
export var devBenefitBussinesMobileDev = "G_fR";
export var domainExpertiseMobileDev = "G_fM";
export var mobileAppDevTechnologies = "G_fJ";
export var mobileAppDevTechnologies__wrapper = "G_fK";
export var ourKeyPrinciplesMobileDev = "G_fQ";
export var serviceMobileTestimonialPost = "G_fP";
export var servicesCardBorderIconMobileDevSection = "G_fG";
export var servicesCardWithTopBorderSectionMobileApp = "G_fH";
export var servicesProcessMobileDevSection = "G_fN";
export var successStoriesMobileSection = "G_fT";
export var weBuildProjectsDifferentSection = "G_fL";
export var whyChooseServicesMobile = "G_fS";