// extracted by mini-css-extract-plugin
export var companyInfo__card = "cb_nS";
export var companyInfo__cardText = "cb_nV";
export var companyInfo__cardTitle = "cb_nT";
export var companyInfo__description = "cb_nR";
export var companyInfo__flex = "cb_n0";
export var companyInfo__head = "cb_nZ";
export var companyInfo__headSm = "cb_nY";
export var companyInfo__title = "cb_nQ";
export var companyInfo__topWrapper = "cb_nX";
export var companyInfo__wrapper = "cb_nW";