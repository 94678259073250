// extracted by mini-css-extract-plugin
export var caseContactFormSection = "br_kv";
export var caseStudyBackground__lineColor = "br_kl";
export var caseStudyHead = "br_kK";
export var caseStudyHead__imageWrapper = "br_kj";
export var caseStudyProjectsSection = "br_kw";
export var caseStudyQuote__bgLight = "br_ks";
export var caseStudyQuote__bgRing = "br_kr";
export var caseStudyVideo__ring = "br_kP";
export var caseStudy__bgDark = "br_kh";
export var caseStudy__bgLight = "br_kg";