import { useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { subscribeSubmitFormEmail } from '~/utils'
import schema from '~/validation/schemas/form.schema'

import { yupResolver } from '@hookform/resolvers/yup'

import 'react-toastify/dist/ReactToastify.min.css'

type SubscribeCV = {
  email: string
}

type useSubscribeFormEmailProps = {
  onSubmitSuccess?: () => void
}

export const useSubscribeFormEmail = ({
  onSubmitSuccess = () => {},
}: useSubscribeFormEmailProps = {}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const defaultValues = {
    email: '',
  }

  const methods = useForm<SubscribeCV>({
    resolver: yupResolver(schema.subscribeSm),
    defaultValues,
  })

  const { reset, handleSubmit } = methods

  const onSubmit = useCallback(
    async (data) => {
      if (!executeRecaptcha) {
        toast.error('Failed to get reCAPTCHA token', {
          position: toast.POSITION.TOP_RIGHT,
        })
        return
      }

      const token = await executeRecaptcha()

      if (!token) {
        toast.error('Failed to get reCAPTCHA token', {
          position: toast.POSITION.TOP_RIGHT,
        })
        return
      }

      await subscribeSubmitFormEmail(data, reset)

      if (onSubmitSuccess) {
        onSubmitSuccess()
      }
    },
    [executeRecaptcha, onSubmitSuccess, reset],
  )

  return { methods, handleSubmit, onSubmit }
}
