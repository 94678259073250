// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bh_kx";
export var caseStudyBackgroundContainer = "bh_kp";
export var caseStudyBackgroundContainer__ship = "bh_kq";
export var caseStudyBackground__bgColor = "bh_km";
export var caseStudyBackground__lineColor = "bh_kn";
export var caseStudyHead__imageWrapper = "bh_kl";
export var caseStudyProjectsSection = "bh_ky";
export var caseStudyQuote__bgLight = "bh_kv";
export var caseStudyQuote__bgRing = "bh_kt";
export var caseStudySolution__ring = "bh_kr";
export var caseStudySolution__ringTwo = "bh_ks";
export var caseStudyTech = "bh_kw";
export var caseStudy__bgDark = "bh_kk";
export var caseStudy__bgLight = "bh_kj";