// extracted by mini-css-extract-plugin
export var socialBlock = "cW_vW";
export var socialBlock__icon = "cW_v1";
export var socialBlock__iconWrapper = "cW_v0";
export var socialBlock__icon_facebook = "cW_v2";
export var socialBlock__icon_instagram = "cW_v5";
export var socialBlock__icon_linkedin = "cW_v3";
export var socialBlock__icon_telegram = "cW_v6";
export var socialBlock__icon_tiktok = "cW_v7";
export var socialBlock__icon_twitter = "cW_v4";
export var socialBlock__iconsWrapper = "cW_vZ";
export var socialBlock__sprite = "cW_vY";
export var socialBlock__title = "cW_vX";