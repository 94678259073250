// extracted by mini-css-extract-plugin
export var eMarketplaceDevBenefitsSection = "G_f2";
export var eMarketplaceDevBusinessSection = "G_fX";
export var eMarketplaceDevCardBrdrIconSection = "G_fV";
export var eMarketplaceDevCaseSection = "G_f3";
export var eMarketplaceDevClientQuotesSection = "G_f5";
export var eMarketplaceDevExpertiseSection = "G_f0";
export var eMarketplaceDevFeaturesSection = "G_fW";
export var eMarketplaceDevIndustriesSection = "G_fY";
export var eMarketplaceDevOurAchievementsSection = "G_f4";
export var eMarketplaceDevProcessSection = "G_fZ";
export var eMarketplaceDevServicesIconSection = "G_fT";
export var eMarketplaceDevTechStackSection = "G_f1";