// extracted by mini-css-extract-plugin
export var onlineMarketplaceAchievementsSection = "w_dm";
export var onlineMarketplaceCardBorderSection = "w_c5";
export var onlineMarketplaceCardRowIconsSection = "w_db";
export var onlineMarketplaceClientQuotesSection = "w_dn";
export var onlineMarketplaceCycleDevSection = "w_c6";
export var onlineMarketplaceCycleDevSection__testimonial = "w_c7";
export var onlineMarketplaceDomainExpertiseSection = "w_c8";
export var onlineMarketplaceExceptionSection = "w_c9";
export var onlineMarketplaceFeaturedCaseSection = "w_dc";
export var onlineMarketplaceFeaturesSection = "w_df";
export var onlineMarketplaceFeaturesSection__box = "w_dg";
export var onlineMarketplaceRequirementSection = "w_dh";
export var onlineMarketplaceSuccessStoriesSection = "w_dk";
export var onlineMarketplaceSuccessfulSection = "w_dd";
export var onlineMarketplaceTechStackSection = "w_dj";
export var onlineMarketplaceWhyHireSection = "w_dl";