// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bK_kv";
export var caseStudyBackground__bgColor = "bK_kk";
export var caseStudyBackground__lineColor = "bK_kl";
export var caseStudyHead__imageAbsolute = "bK_kX";
export var caseStudyHead__imageWrapper = "bK_kj";
export var caseStudyProjectsSection = "bK_kw";
export var caseStudyQuote__bgRing = "bK_kr";
export var caseStudyTech = "bK_kt";
export var caseStudyVideo = "bK_kR";
export var caseStudyVideo__ring = "bK_kP";
export var caseStudy__bgDark = "bK_kh";
export var caseStudy__bgLight = "bK_kg";