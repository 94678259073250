// extracted by mini-css-extract-plugin
export var pwaBenefitWebsiteSection = "w_cP";
export var pwaCardRowIconsSection = "w_cS";
export var pwaCycleDevelopmentSection = "w_cQ";
export var pwaCycleDevelopmentSection__testimonial = "w_cR";
export var pwaDomainExpertiseSection = "w_cW";
export var pwaFeaturedCaseSection = "w_cX";
export var pwaServiceAchievementsSection = "w_c1";
export var pwaServiceClientsSection = "w_c2";
export var pwaServicesCompaniesSection = "w_cT";
export var pwaServicesCycleDevelopmentSection = "w_cV";
export var pwaSuccessStoriesSection = "w_cZ";
export var pwaTechStackSection = "w_cY";
export var pwaWhyChooseSection = "w_c0";