// extracted by mini-css-extract-plugin
export var authorHead__aboutAuthorDescription = "d6_HN";
export var authorHead__aboutAuthorTitle = "d6_HM";
export var authorHead__aboutAuthorWrapper = "d6_HL";
export var authorHead__description = "d6_HJ";
export var authorHead__flexWrapper = "d6_HD";
export var authorHead__name = "d6_HG";
export var authorHead__photo = "d6_HF";
export var authorHead__position = "d6_HH";
export var authorHead__socialWrapper = "d6_HK";
export var authorHead__wrapper = "d6_HC";