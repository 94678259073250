// extracted by mini-css-extract-plugin
export var careerWorking__btn = "dN_FV";
export var careerWorking__btnWrapper = "dN_FT";
export var careerWorking__card = "dN_FJ";
export var careerWorking__cardBgDark = "dN_FL";
export var careerWorking__cardBgLight = "dN_FK";
export var careerWorking__cardDescription = "dN_FR";
export var careerWorking__cardHorizontal = "dN_FM";
export var careerWorking__cardHorizontalBox = "dN_FN";
export var careerWorking__cardHorizontalImage = "dN_FP";
export var careerWorking__cardImage = "dN_FS";
export var careerWorking__cardTitle = "dN_FQ";