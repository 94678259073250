// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b4_kx";
export var caseStudyBackground__lineColor = "b4_kn";
export var caseStudyHead__imageWrapper = "b4_nv";
export var caseStudyProjectsSection = "b4_ky";
export var caseStudyQuote__bgRing = "b4_kt";
export var caseStudyQuote__bottomVideo = "b4_kH";
export var caseStudyQuote__light = "b4_mY";
export var caseStudyQuote__lightLast = "b4_nw";
export var caseStudyVideo__ring = "b4_kB";
export var caseStudy__bgDark = "b4_kk";
export var caseStudy__bgLight = "b4_kj";
export var caseStudy__bgLightReverse = "b4_kP";