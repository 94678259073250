// extracted by mini-css-extract-plugin
export var productDiscoverySession__card = "dr_By";
export var productDiscoverySession__cardFour = "dr_BD";
export var productDiscoverySession__cardOne = "dr_Bz";
export var productDiscoverySession__cardThree = "dr_BC";
export var productDiscoverySession__cardTwo = "dr_BB";
export var productDiscoverySession__cardWrapper = "dr_Bx";
export var productDiscoverySession__description = "dr_BH";
export var productDiscoverySession__header = "dr_Bw";
export var productDiscoverySession__image = "dr_BJ";
export var productDiscoverySession__info = "dr_BF";
export var productDiscoverySession__title = "dr_BG";