// extracted by mini-css-extract-plugin
export var chatGptExpertiseCases__between = "dv_BZ";
export var chatGptExpertiseCases__card = "dv_B3";
export var chatGptExpertiseCases__contentAll = "dv_B8";
export var chatGptExpertiseCases__contentOne = "dv_B6";
export var chatGptExpertiseCases__contentTwo = "dv_B7";
export var chatGptExpertiseCases__description = "dv_B2";
export var chatGptExpertiseCases__imageDesktop = "dv_B0";
export var chatGptExpertiseCases__title = "dv_B1";
export var chatGptExpertiseCases__wrapperLeft = "dv_B4";
export var chatGptExpertiseCases__wrapperRight = "dv_B5";