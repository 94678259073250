// extracted by mini-css-extract-plugin
export var designDevelopmentExpertsSection = "q_bR";
export var designDevelopmentServices = "q_bP";
export var designDifferenceBetweenSection = "q_bQ";
export var designFeaturedCaseSection = "q_bY";
export var designFullVideoSection = "q_bT";
export var designInsightsSection = "q_bZ";
export var designPhaseSection = "q_bW";
export var designPrimeSection = "q_bN";
export var designProcessSection = "q_bV";
export var designTestimonialPosSection = "q_bS";
export var domainExpertiseDesign = "q_bX";