// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bg_kv";
export var caseStudyBackgroundContainer = "bg_km";
export var caseStudyBackgroundContainer__ship = "bg_kn";
export var caseStudyBackground__bgColor = "bg_kk";
export var caseStudyBackground__lineColor = "bg_kl";
export var caseStudyHead__imageWrapper = "bg_kj";
export var caseStudyProjectsSection = "bg_kw";
export var caseStudyQuote__bgLight = "bg_ks";
export var caseStudyQuote__bgRing = "bg_kr";
export var caseStudySolution__ring = "bg_kp";
export var caseStudySolution__ringTwo = "bg_kq";
export var caseStudyTech = "bg_kt";
export var caseStudy__bgDark = "bg_kh";
export var caseStudy__bgLight = "bg_kg";