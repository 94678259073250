// extracted by mini-css-extract-plugin
export var aiAwardsSection = "W_jC";
export var aiBenefitsSection = "W_jv";
export var aiBusinessSection = "W_jy";
export var aiCasesSection = "W_jz";
export var aiCompanySection = "W_jB";
export var aiIndustriesSection = "W_jt";
export var aiPracticesSection = "W_jw";
export var aiPrimeSection = "W_jr";
export var aiProcessSection = "W_jx";
export var aiQuoteSection = "W_jD";
export var aiServicesIconSection = "W_js";